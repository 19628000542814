import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';
import { Icon } from '@virtidev/toolbox';
import * as Styled from './MicBehaviorSelector.styled';
import { useTheme } from 'styled-components';
import { MicBehaviorContext } from '../../../../../contexts/MicBehaviorProvider/MicBehaviorProvider';

const MicBehaviorSelector = ({ disabled }: { disabled: boolean }) => {
  const { micBehavior, setMicBehavior, micActive } =
    useContext(MicBehaviorContext);
  const theme = useTheme();
  const handleClickPushToTalk = useCallback(() => {
    setMicBehavior('push_to_talk');
  }, [setMicBehavior]);
  const handleClickActivateWithVoice = useCallback(() => {
    setMicBehavior('open');
  }, [setMicBehavior]);

  return (
    <Styled.Wrapper>
      <Styled.MicBehaviorTooltip
        iconSize="18px"
        icon="dots"
        autoClose
        type="menu"
        disabled={disabled}
      >
        <Styled.Menu>
          <Styled.CardButton
            disabled={micActive}
            onClick={handleClickPushToTalk}
          >
            Push to talk
            {micBehavior === 'push_to_talk' && (
              <Icon color={theme.color.success} icon="tick" />
            )}
          </Styled.CardButton>
          <Styled.CardButton
            disabled={micActive}
            onClick={handleClickActivateWithVoice}
          >
            Voice activated
            {micBehavior === 'open' && (
              <Icon color={theme.color.success} icon="tick" />
            )}
          </Styled.CardButton>
          {micActive && (
            <div>Please deactivate the microphone to change behavior.</div>
          )}
        </Styled.Menu>
      </Styled.MicBehaviorTooltip>
    </Styled.Wrapper>
  );
};

MicBehaviorSelector.propTypes = { disabled: PropTypes.bool.isRequired };

export default MicBehaviorSelector;
