import { useVHStateStore } from '../../stores/VHStateStore/VHStateStore';
import { StyledCTAModal } from './CTAModal.styled';
import CTAModalContent from './CTAModalContent';

const CTAModal = ({ onRestart }: { onRestart: () => void }) => {
  const ctaModalActiveType = useVHStateStore(
    (state) => state.ctaModalActiveType
  );
  const setCtaModalVisible = useVHStateStore(
    (state) => state.setCtaModalActiveType
  );

  console.log('ctaModalActiveType');
  console.log(ctaModalActiveType);

  return (
    <StyledCTAModal
      onHide={() => setCtaModalVisible(null)}
      show={!!ctaModalActiveType}
    >
      <CTAModalContent onRestart={onRestart} />
    </StyledCTAModal>
  );
};

export default CTAModal;
