import styled from 'styled-components';

export const HubSpotFormWrapper = styled.div`
  padding: 40px;
  margin: 0 auto;
  max-width: 700px;
`;

export const VirtiLink = styled.a`
  display: block;
  width: 130px;
  margin: 0 auto;
`;

export const Heading = styled.h2`
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: -0.36px;
  margin: 1.5em auto 1.5em;
`;
