import { createContext, useState } from 'react';

export const MicMutedContext = createContext({
  muted: true,
  setMuted: (_newVal: boolean) => {},
});

export const MicMutedProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [muted, setMuted] = useState(true);
  return (
    <MicMutedContext.Provider value={{ muted, setMuted }}>
      {children}
    </MicMutedContext.Provider>
  );
};

export default MicMutedProvider;
