import * as Styled from './MobileMenu.styled';
import { Button } from '@virtidev/toolbox';
import { getModeUrl } from '../../../utility/RouteUtils';
import { useCallback } from 'react';
import { trackCtaClick } from '../../../utility/Tracking';

const MobileMenu = ({
  onRestart,
  isOpen,
}: {
  onRestart?: () => void;
  isOpen: boolean;
}) => {
  const trackTrialClick = useCallback(
    () => trackCtaClick('free_trial', 'header'),
    []
  );
  const trackDemoClick = useCallback(
    () => trackCtaClick('book_demo', 'header'),
    []
  );

  return (
    <Styled.MobileMenuWrapper $isOpen={isOpen}>
      <Button
        as="a"
        color="turquoise"
        href={getModeUrl('book_demo')}
        target="_blank"
        onClick={trackDemoClick}
        aria-label="Opens a new tab"
      >
        Book a demo
      </Button>
      <Button
        color="primary"
        as="a"
        href="https://app.virti.com/register"
        target="_blank"
        onClick={trackTrialClick}
        aria-label="Opens a new tab"
      >
        Try free for 14 days
      </Button>
      {onRestart && (
        <Button
          color="turquoise"
          design="outline"
          type="submit"
          onClick={onRestart}
          icon="loop"
        >
          Try another scenario
        </Button>
      )}
    </Styled.MobileMenuWrapper>
  );
};

export default MobileMenu;
