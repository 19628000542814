import styled from 'styled-components';
import { ChatType } from '../../../../stores/ChatsStore/ChatsStore.type';

export const Item = styled.div<{ chatType: ChatType }>`
  display: flex;
  position: relative;
  justify-content: center;
  a {
    color: ${(props) => props.theme.color.secondary};
  }
`;

export const ChatBubbleWrapper = styled.div<{ chatType: ChatType }>``;

export const ChatNameWrapper = styled.div`
  margin-bottom: 0.5rem;
  max-width: 8rem;
  line-height: 1rem;
`;
