import * as Styled from './LoadingFlavourText.styled';

export const LoadingFlavourText = ({
  loadingFlavourTexts,
  progress,
}: {
  loadingFlavourTexts: string[];
  progress: number;
}) => {
  // gets flavour text based on the current progress (as progress goes up, the flavour text will change)
  // an alternative method might be to do it on an interval
  const getFlavourText = (progress: number) => {
    const defaultFlavourText = 'Loading...';
    const percIncrement = 100 / loadingFlavourTexts.length;
    // return first text that goes over current progress
    for (let i = 0; i < loadingFlavourTexts.length; i++) {
      if (
        // some mathematical overlap with >= and <= but means we capture 0 and 100
        progress >= i * percIncrement &&
        progress <= (i + 1) * percIncrement
      ) {
        return loadingFlavourTexts[i];
      }
    }
    // shouldn't reach this point
    return defaultFlavourText;
  };
  return (
    <Styled.LoadingFlavourText>
      {getFlavourText(progress)}
    </Styled.LoadingFlavourText>
  );
};
