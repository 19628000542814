import { Button, Modal } from '@virtidev/toolbox';
import styled from 'styled-components';

export const StyledStartModal = styled(Modal)`
  .modal-box {
    max-width: 660px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 18px;
`;
export const ScenarioImageWrapper = styled.div`
  img {
    width: 100%;
    max-width: 400px;
    border-radius: 6px;
    @media (max-width: 600px) {
      margin-top: -20px; // Eat in to space reserved for title
    }
  }
`;
export const ScenarioName = styled.h2`
  color: ${({ theme: { color } }) => color.midnight};
  text-align: left;
  font-family: 'Ubuntu', sans-serif;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  line-height: 32px;
  letter-spacing: -0.36px;
  max-width: 18em;
`;
export const ScenarioObjective = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  max-width: 40em;
  margin: 0 auto;
  font-family: 'Cabin', sans-serif;
  color: ${({ theme: { color } }) => color.midnight10};
`;
export const StartButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: auto;
`;
