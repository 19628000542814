import { useSelectedTemplateStore } from '../../../stores/SelectedTemplateStore/SelectedTemplateStore';
import { useCallback, useEffect } from 'react';
import { useUserVHDataStore } from '../../../stores/UserVHDataStore/UserVHDataStore';
import * as Styled from './VHTemplateForm.styled';
import { PlayerTemplate } from '../../../types/PlayerTemplate.type';
import * as StyledForms from '../VHForms.styled';
import { VisibleVHTemplates } from '../VHTemplates.data';
import TemplateSelectButton from './TemplateSelectButton/TemplateSelectButton';
import StepsBanner from '../../StepsBanner/StepsBanner';
import Header from '../../Header/Header';
import { Main } from '../../../App.styled';
import { track } from '../../../utility/Tracking';
import { AppStage, getStep } from '../../../utility/AppStages';
import SectionHeader from '../../SectionHeader/SectionHeader';

const VHTemplateForm = ({ onNextStage }: { onNextStage: () => void }) => {
  const selectedTemplate = useSelectedTemplateStore(
    (state) => state.selectedTemplate
  );
  const setSelectedTemplate = useSelectedTemplateStore(
    (state) => state.setSelectedTemplate
  );
  const setVHData = useUserVHDataStore((state) => state.setUserVHData);

  const handleContinue = () => {
    setVHData({
      characterInformation: selectedTemplate?.characterHints.join('\n'),
      vhName: selectedTemplate?.vhName,
      scenarioObjective: selectedTemplate?.scenarioObjective,
      greeting: selectedTemplate?.greeting ?? '',
      openness: selectedTemplate?.openness,
      confidence: selectedTemplate?.confidence,
      aggressiveness: selectedTemplate?.aggressiveness,
      chattiness: selectedTemplate?.chattiness,
      personalityTraits: selectedTemplate?.personalityTraits,
      background: selectedTemplate?.background,
    });
    track('step_completed', {
      step: getStep(AppStage.VH_TEMPLATE_SELECTOR),
      scenario: selectedTemplate?.templateName,
    });
    onNextStage();
  };

  useEffect(() => {
    setSelectedTemplate(VisibleVHTemplates[0]);
  }, [setSelectedTemplate]);

  const handleTemplateSelect = useCallback(
    (template: PlayerTemplate) => {
      setSelectedTemplate(template);
    },
    [setSelectedTemplate]
  );

  const currentStep = 1;
  const totalSteps = 3;

  return (
    <>
      <Header />
      <Main id="main">
        <StyledForms.SectionWrapper>
          <StyledForms.Section>
            <header>
              <SectionHeader
                totalSteps={totalSteps}
                currentStep={currentStep}
                title="Choose your scenario"
              />
              <StyledForms.HeaderContent>
                <p>
                  Start with a role-play scenario that best suits your area of
                  interest.
                  <br /> You can customize your chosen scenario at the next
                  step.
                </p>
                <Styled.Disclaimer>
                  Use of this demo is subject to Virti’s{' '}
                  <a
                    href="https://www.virti.com/virti-privacy-policy-website-and-direct-marketing/"
                    target="_blank"
                    aria-label="Opens a new tab"
                  >
                    privacy policy
                  </a>
                  .
                </Styled.Disclaimer>
              </StyledForms.HeaderContent>
            </header>
            <Styled.TemplateButtons>
              {VisibleVHTemplates.map((template) => (
                <TemplateSelectButton
                  key={template.templateName}
                  onTemplateSelect={handleTemplateSelect}
                  selectedTemplate={selectedTemplate}
                  template={template}
                />
              ))}
            </Styled.TemplateButtons>
          </StyledForms.Section>
        </StyledForms.SectionWrapper>
      </Main>
      <StepsBanner
        currentStep={currentStep}
        totalSteps={totalSteps}
        buttonText={'Customize'}
        onContinue={handleContinue}
      />
    </>
  );
};

export default VHTemplateForm;
