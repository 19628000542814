import { PlayerTemplate } from '../../../../types/PlayerTemplate.type';
import { useCallback } from 'react';
import * as Styled from './TemplateSelectButton.styled';
import { Icon } from '@virtidev/toolbox';
import { useTheme } from 'styled-components';

const TemplateSelectButton = ({
  template,
  selectedTemplate,
  onTemplateSelect,
}: {
  template: PlayerTemplate;
  selectedTemplate: PlayerTemplate | null;
  onTemplateSelect: (newTemplate: PlayerTemplate) => void;
}) => {
  const handleTemplateSelect = useCallback(() => {
    onTemplateSelect(template);
  }, [onTemplateSelect, template]);

  const isActive =
    selectedTemplate && selectedTemplate.templateName === template.templateName
      ? true
      : false;

  const theme = useTheme();

  return (
    <Styled.TemplateButton
      key={template.templateName}
      $active={isActive}
      onClick={handleTemplateSelect}
    >
      <img src={template?.avatarSrc} alt={selectedTemplate?.avatarAltTag} />
      <Styled.TemplateContent>
        <Styled.TitleWrapper>
          <Styled.TemplateTypeText $active={isActive}>
            {template.templateName}
          </Styled.TemplateTypeText>
          <Styled.TickWrapper $active={isActive}>
            {isActive && <Icon color={theme.color.pink} icon="tick" />}
          </Styled.TickWrapper>
        </Styled.TitleWrapper>
        <Styled.TemplateScenarioText>
          {template.templateDescription}
        </Styled.TemplateScenarioText>
      </Styled.TemplateContent>
    </Styled.TemplateButton>
  );
};

export default TemplateSelectButton;
