import { ReactNode } from 'react';
import * as Styled from './FormLabel.styled';
import { HelpTooltip, Icon } from '@virtidev/toolbox';
import { useTheme } from 'styled-components';

const FormLabel = ({
  locked,
  children,
  htmlFor,
  tooltipText,
}: {
  locked?: boolean;
  children: ReactNode;
  htmlFor?: string;
  tooltipText?: string;
}) => {
  const theme = useTheme();
  return (
    <Styled.FormLabel htmlFor={htmlFor} $locked={locked}>
      {locked && (
        <Icon
          title="This item cannot be edited."
          type="outline"
          icon="padlock"
          color={theme.color.darkGrey}
        />
      )}
      <div>{children}</div>
      <Styled.TooltipWrapper>
        <HelpTooltip>{tooltipText}</HelpTooltip>
      </Styled.TooltipWrapper>
    </Styled.FormLabel>
  );
};

export default FormLabel;
