import styled from 'styled-components';

export const SectionHeaderWrapper = styled.div``;

export const SectionHeading = styled.h1`
  color: ${({ theme }) => theme.color.midnight};
  font-size: 28px;
  font-weight: 500;
  margin: 56px 0 8px;
`;

export const StepsWrapper = styled.div`
  margin: 26px 0 0;

  + ${SectionHeading} {
    margin: 34px 0;
  }
`;
