/**
 * Determine if this is a mobile operating system.
 *
 * @returns {String | null}
 */
export const IsMobile = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  if (!userAgent) {
    return null;
  }

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (
    (/iPad|iPhone|iPod/.test(userAgent) && (window as any).MSStream) ||
    (userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return 'iOS';
  }

  return null;
};

export function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}
