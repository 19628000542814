import { Button, Icon } from '@virtidev/toolbox';
import styled from 'styled-components';
import { SectionWrapper } from '../VHForms/VHForms.styled';

export const HeaderWrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.color.midnight};
`;

export const SkipLink = styled.a`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    position: static;
    width: auto;
    height: auto;
  }
`;

export const Header = styled.header<{ $mobileLayout?: boolean }>`
  min-height: ${(props) => (props.$mobileLayout ? '40px' : '100px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-shrink: 0;
`;
export const VirtiWrapper = styled.div`
  display: flex;
  width: 125px;
`;
export const VirtiLink = styled.a`
  display: flex;
  text-transform: uppercase;
  align-items: flex-end;
  gap: 24px;
  text-decoration: none;
  padding-bottom: 4px;
`;
export const VirtiBackIcon = styled(Icon)`
  transform: rotate(180deg);
`;

export const CTAButtons = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  font-family: 'Cabin', sans-serif;
`;

export const MenuButton = styled.button`
  color: ${(props) => props.theme.color.primary};
  border: unset;
  padding: unset;
  background: unset;
  font: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

export const TryFreeButton = styled(Button)`
  /* background: none; */
  border: 2px solid ${({ theme }) => theme.color.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: 0 24px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.color.contrast.midnight};
  height: 44px;
  font-weight: 700;
  &:hover {
    background: ${({ theme }) => theme.color.turquoise10};
    color: ${({ theme }) => theme.color.midnight};
  }
`;
