import styled from 'styled-components';
import { ChatType } from '../../../../../stores/ChatsStore/ChatsStore.type';

export const BaseBubble = styled.div`
  padding: 10px 20px;
  white-space: pre-line; /* ensure line breaks are implemented */
  position: relative;
  word-break: break-word;
  pointer-events: all;
  border-radius: 20px;
  background: ${(props) => props.theme.color.midnight}BB;
  color: #fff;
  margin: auto;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  max-height: 28svh;
  overflow: auto;
  /* @media (max-width: 800px) {
    background: none;
    padding: 0;
  } */
`;

export const Bubble = styled(BaseBubble)<{ chatType: ChatType }>``;
