import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './RecordingButton.styled';
import { Icon } from '@virtidev/toolbox';
import { ThemeContext } from 'styled-components';
import { MicrophoneStreamContext } from '../../../../../../contexts/MicrophoneStreamProvider/MicrophoneStreamProvider';
import { usePrevious } from '../../../../../../utility/hooks/StateHooks';
import { MicBehaviorContext } from '../../../../../../contexts/MicBehaviorProvider/MicBehaviorProvider';
import useMicStreamVolume from '../../../../../../contexts/MicrophoneStreamProvider/useMicStreamVolume';
import RecordingPopovers from './RecordingIssuePopovers/RecordingPopovers';
import MutedNotification from '../../../../MutedNotification/MutedNotification';

const RecordingButton = ({
  disabled,
  onClick,
  muted,
  connecting,
  connected,
  showMutedNoticeIfTalked,
  networkError,
  recording,
}: {
  disabled: boolean;
  onClick: () => void;
  muted: boolean;
  connecting: boolean;
  connected: boolean;
  showMutedNoticeIfTalked: boolean;
  networkError: boolean;
  recording?: boolean;
}) => {
  const { error: micError, stream } = useContext(MicrophoneStreamContext);
  const prevConnected = usePrevious(connected);
  useEffect(() => {
    if (connected && !prevConnected && muted && !micError && stream) {
      onClick();
    }
  }, [muted, onClick, prevConnected, connected, micError, stream]);
  const recordBtnRef = useRef<HTMLButtonElement>(null);

  const [showMicUnavailable, setShowMicUnavailable] = useState(false);

  // keep track of muted count so we can remount the muted notification
  const [mutedCount, setMutedCount] = useState(1);
  const handleOnClick = () => {
    setMutedCount((curCount) => curCount + 1);
    if (micError) {
      setShowMicUnavailable(true);
    } else {
      onClick();
    }
  };
  const buttonDisabled = disabled;

  const { micBehavior } = useContext(MicBehaviorContext);

  const micVolume = useMicStreamVolume();
  const theme = useContext(ThemeContext);
  return (
    <Styled.SpeechToTextWrapper>
      {/* {!connected && (
        <Styled.ConnectionError title="Connection error when connecting to Speech to Text service. Click unmute to retry connection.">
          <Icon icon="error" />
          Error
        </Styled.ConnectionError>
      )} */}
      <RecordingPopovers
        showMicUnavailable={showMicUnavailable}
        setShowMicUnavailable={setShowMicUnavailable}
        attachToRef={recordBtnRef}
        networkError={networkError}
      />
      <Styled.RecordingButton
        $muted={(muted || !connected) && !recording}
        disabled={buttonDisabled}
        onClick={handleOnClick}
        $micVolume={micVolume}
        ref={recordBtnRef}
        $micBehavior={micBehavior}
        style={{
          outline:
            !muted &&
            !!stream &&
            (micBehavior === 'open' || recording) &&
            !buttonDisabled
              ? `${Math.min(micVolume * 50, 6)}px solid ${
                  theme.color.primaryLight
                }`
              : 'none',
        }}
      >
        <Icon
          icon={
            (micBehavior === 'open' && (connected || connecting)) ||
            (micBehavior === 'push_to_talk' &&
              (connected || connecting) &&
              recording)
              ? 'microphone'
              : 'microphoneoff'
          }
          size="1.4rem"
          type="outline"
        />
        {!muted && connected && !buttonDisabled && <Styled.Circle />}
      </Styled.RecordingButton>
      {/* <VolumeMeter disabled={disabled} active={!muted} /> */}
      <MutedNotification
        showMutedNoticeIfTalked={showMutedNoticeIfTalked}
        active={!muted}
        key={mutedCount}
      />
    </Styled.SpeechToTextWrapper>
  );
};

RecordingButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onMicUnavailable: PropTypes.func,
  showMutedNoticeIfTalked: PropTypes.bool,
  networkError: PropTypes.bool,
};

export default RecordingButton;
