import { useCallback, useContext, useEffect } from 'react';
import { useDebounce, usePrevious } from '@uidotdev/usehooks';
import useDeepgram from '../../../../../utility/useDeepgram';
import { MicMutedContext } from '../../../../../contexts/MicMutedProvider/MicMutedProvider';
import { useVHStateStore } from '../../../../../stores/VHStateStore/VHStateStore';
import { useUpdateMicActive } from '../../../../../contexts/MicBehaviorProvider/useUpdateMicActive';
import RecordingButton from '../ChatInput/RecordingButton/RecordingButton';

const DeepgramButton = ({
  onSubmit,
  onChange,
  textDisabled,
  recordingDisabled,
  onSpeechError,
}: {
  onSubmit: (chat: string) => void;
  onChange: (e: string) => void;
  textDisabled: boolean;
  recordingDisabled: boolean;
  onSpeechError: (err: string) => void;
}) => {
  const { muted, setMuted } = useContext(MicMutedContext);
  const virtualHumanTalking = useVHStateStore((state) => state.isTalking);
  // const language = useSelector(languageSelector);
  const isSendingChat = useVHStateStore((state) => state.isSendingChat);

  const showMutedNoticeIfTalked =
    muted && !virtualHumanTalking && !isSendingChat;

  const pauseDeepgram =
    muted || textDisabled || virtualHumanTalking || isSendingChat;
  // use debounced variable so that it doesn't flick between paused and unpaused in the moment that they change
  const debouncedPauseDeepgram = useDebounce(pauseDeepgram, 50);
  console.log('debouncedPauseDeepgram');
  console.log(debouncedPauseDeepgram);
  const {
    finalAffirmation,
    error,
    connected,
    connecting,
    reconnect: _reconnect,
    currentTranscript,
  } = useDeepgram({
    // language,
    paused: debouncedPauseDeepgram,
  });

  const pausedTranscriptions = muted || textDisabled || virtualHumanTalking;

  // if mic stops listening and a transcript exists, submit that newly spoken text
  const prevFinalAffirmation = usePrevious(finalAffirmation);
  useEffect(() => {
    if (
      !pausedTranscriptions &&
      finalAffirmation &&
      !muted &&
      !virtualHumanTalking &&
      !textDisabled
    ) {
      console.log('prevFinalAffirmation');
      console.log(prevFinalAffirmation);
      console.log('finalAffirmation');
      console.log(finalAffirmation);
      console.log('send!!!!!!!!!!!!!!!!!!!!!!!!!');
      onSubmit(finalAffirmation);
    }
  }, [
    finalAffirmation,
    onSubmit,
    virtualHumanTalking,
    prevFinalAffirmation,
    textDisabled,
    muted,
    pausedTranscriptions,
  ]);

  const handleRecordingButtonClick = useCallback(() => {
    if (!recordingDisabled) {
      setMuted(!muted);
    }
  }, [recordingDisabled, muted, setMuted]);

  useEffect(() => {
    if (currentTranscript && !debouncedPauseDeepgram) {
      onChange(currentTranscript);
    }
  }, [onChange, currentTranscript, debouncedPauseDeepgram]);

  useEffect(() => {
    if (error) {
      onSpeechError(error);
    }
  }, [error, onSpeechError]);

  useUpdateMicActive(!muted);

  return (
    <RecordingButton
      disabled={recordingDisabled}
      onClick={handleRecordingButtonClick}
      muted={muted}
      connected={connected}
      showMutedNoticeIfTalked={showMutedNoticeIfTalked}
      networkError={!connected && error}
      connecting={connecting}
    />
  );
};

export default DeepgramButton;
