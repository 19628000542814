import styled, { css } from 'styled-components';

export const CloseBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
`;

export const Popover = styled.div<{
  $active: boolean;
  $left: string;
  $top: string;
  $position: string;
  $arrowPosX: number;
  $type: string;
}>`
  position: absolute;
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s 0.1s ease-in-out;
  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
  left: ${(props) => props.$left};
  top: ${(props) => props.$top};
  background-color: var(--primary-bg-color);
  z-index: 4;
  box-shadow: 0 3px 5px #020202;
  padding: 0.7rem;
  width: max-content;
  max-width: 280px;
  line-height: 1.3rem;
  background: var(--warning-color-2);
  color: #fff;
  ${(props) =>
    props.$position === 'top-right' &&
    css`
      border-radius: var(--card-border-radius) var(--card-border-radius)
        var(--card-border-radius) 0;
    `}
  ${(props) =>
    props.$position === 'bottom-right' &&
    css`
      border-radius: 0 var(--card-border-radius) var(--card-border-radius)
        var(--card-border-radius);
    `}
  ${(props) =>
    props.$position === 'bottom-left' &&
    css`
      border-radius: ${({ theme }) =>
        `${theme.borderRadius.lg} 0 ${theme.borderRadius.lg} ${theme.borderRadius.lg}`};
    `}
  ${({ theme, $position, $arrowPosX }) =>
    $position === 'top' &&
    css`
      border-radius: ${theme.borderRadius.md};
      ::after {
        content: '';
        position: absolute;
        top: 100%;
        left: ${$arrowPosX * 100}%;
        margin-left: -6px;
        border-width: 6px;
        border-style: solid;
        border-color: ${theme.color.secondaryLight} transparent transparent
          transparent;
        filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 1));
      }
    `}
  ${(props) =>
    props.$type === 'info' &&
    css`
      background-color: ${(props) => props.theme.color.secondaryLight};
      color: ${(props) => props.theme.color.contrast.secondaryLight};
      ${CloseBtn} {
        color: ${(props) => props.theme.color.contrast.secondaryLight};
      }
    `}
  ${(props) =>
    props.$type === 'error' &&
    css`
      background-color: ${(props) => props.theme.color.errorDark};
      color: ${(props) => props.theme.color.contrast.errorDark};
      ${CloseBtn} {
        color: ${(props) => props.theme.color.contrast.errorDark};
      }
      /* only currently applies to when position is top */
      ::after {
        border-color: ${(props) => props.theme.color.errorDark} transparent
          transparent transparent;
      }
    `}
`;
export const Content = styled.div``;
