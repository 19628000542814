import { useEffect } from 'react';
import * as Styled from './WebPlayerTimer.styled';
import { useStopwatchTimer } from './useStopwatchTimer';
import { usePrevious } from '../../../utility/hooks/StateHooks';

export const ClockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.653"
    height="20.653"
    viewBox="0 0 20.653 20.653"
    fill="#111b32"
  >
    <defs></defs>
    <g transform="translate(-381.683 -3698.421)">
      <g transform="translate(381.683 3698.421)">
        <path
          className="a"
          d="M133.421,98.581l3.427-2.491a.505.505,0,0,0,.208-.409V88.943a.509.509,0,0,0-.507-.507h-.591a.509.509,0,0,0-.507.507v6.175l-2.976,2.166a.506.506,0,0,0-.11.709l.347.477A.51.51,0,0,0,133.421,98.581Z"
          transform="translate(-125.555 -83.552)"
        />
        <path
          className="a"
          d="M139.245,86a10.326,10.326,0,1,1-10.327,10.325A10.323,10.323,0,0,1,139.245,86Zm0,18.779a8.453,8.453,0,1,0-8.45-8.454A8.442,8.442,0,0,0,139.245,104.778Z"
          transform="translate(-128.918 -85.999)"
        />
      </g>
    </g>
  </svg>
);

const WebPlayerTimer = ({
  onComplete,
  startTime,
}: {
  onComplete: () => void;
  startTime: number;
}) => {
  const TIME_LIMIT = 60 * 3;
  const { timerState, startTimer } = useStopwatchTimer(
    startTime,
    TIME_LIMIT,
    onComplete
  );
  const prevStartTime = usePrevious(startTime);

  useEffect(() => {
    if (
      !timerState.running &&
      !timerState.finished &&
      startTime !== prevStartTime
    ) {
      startTimer();
    }
  }, [
    startTimer,
    timerState.running,
    timerState.finished,
    prevStartTime,
    startTime,
  ]);

  if (timerState.finished || timerState.msRemaining <= 0) {
    return <></>;
  }

  return (
    <Styled.Wrapper>
      <Styled.TimeWrapper>
        {ClockIcon}
        <div>{timerState.formattedTimeRemaining}</div>
      </Styled.TimeWrapper>
    </Styled.Wrapper>
  );
};

WebPlayerTimer.propTypes = {};

export default WebPlayerTimer;
