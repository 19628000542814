import { useWindowSize } from '@uidotdev/usehooks';

export const useIsMobileFooterLayout = function () {
  const { width: windowWidth } = useWindowSize();
  const mobileLayout = windowWidth && windowWidth <= 600;
  return mobileLayout;
};

export const useIsWidthGte = function (width: number) {
  const { width: windowWidth } = useWindowSize();
  return windowWidth && windowWidth >= width;
};
