import { useCallback, useState, useEffect, useMemo } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { PlayerTemplate } from '../../../types/PlayerTemplate.type';
import * as Styled from './VHUnityPlayer.styled';
import { File } from '../../../types/File.type';
import UnityLoader from '../UnityLoader/UnityLoader';
import { IsMobile } from '../../../utility/BrowserChecks';
import { useChatsStore } from '../../../stores/ChatsStore/ChatsStore';

type PDUnityInnerProps = {
  onLoaded: () => void;
  template: PlayerTemplate;
  onVirtualHumanFinishedTalking: () => void;
  audioToPlay: File | null;
  onVirtualHumanStartTalking: () => void;
};

const loadingFlavourTexts = [
  'Constructing personality...',
  'Generating environment...',
  'Repositioning props...',
  'Growing virtual brain...',
  'Educating...',
];

const VHUnityPlayer = ({
  onLoaded,
  template,
  onVirtualHumanFinishedTalking,
  audioToPlay,
  onVirtualHumanStartTalking,
}: PDUnityInnerProps) => {
  const baseURL = 'https://d13yzhuqbz6qtt.cloudfront.net/virtual-human/teaser/';
  let folder = IsMobile() ? 'Mobile/' : 'Desktop/';

  const searchParamFolder = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('buildfolder');
  }, []);
  if (searchParamFolder) {
    folder = searchParamFolder.replace(/[^\w\s]/gi, '') + '/';
  }
  const url = baseURL + folder;

  const {
    unityProvider,
    addEventListener,
    removeEventListener,
    sendMessage,
    loadingProgression,
  } = useUnityContext({
    loaderUrl: `${url}WebGL-PD_2020.loader.js`,
    dataUrl: `${url}WebGL-PD_2020.data.unityweb`,
    frameworkUrl: `${url}WebGL-PD_2020.framework.js.unityweb`,
    codeUrl: `${url}WebGL-PD_2020.wasm.unityweb`,
  });

  const [loadingStage, setLoadingStage] = useState<
    'initial' | 'assets' | false
  >('initial');

  const handleOnLoaded = useCallback(() => {
    setLoadingStage(false);
  }, [setLoadingStage]);

  useEffect(() => {
    addEventListener('loaded', handleOnLoaded);
    return () => {
      removeEventListener('loaded', handleOnLoaded);
    };
  }, [addEventListener, handleOnLoaded, removeEventListener]);

  const handleOnPlayerLoaded = useCallback(() => {
    const avatarAndEnvironment = JSON.stringify({
      avatarName: template.avatar,
      environmentName: template.environment,
    });
    sendMessage(
      'WebBridge',
      'SelectAvatarAndEnvironment',
      avatarAndEnvironment
    );
    setLoadingStage('assets');
  }, [setLoadingStage, template?.avatar, template?.environment, sendMessage]);

  useEffect(() => {
    addEventListener('PlayerLoaded', handleOnPlayerLoaded);
    return () => {
      removeEventListener('PlayerLoaded', handleOnPlayerLoaded);
    };
  }, [addEventListener, handleOnPlayerLoaded, removeEventListener]);

  const setAwaitingUnityAudioPlay = useChatsStore(
    (state) => state.setAwaitingUnityAudioPlay
  );
  const handleOnResponseAudioStarted = useCallback(() => {
    setAwaitingUnityAudioPlay(false);
  }, [setAwaitingUnityAudioPlay]);

  useEffect(() => {
    addEventListener('ResponseAudioStarted', handleOnResponseAudioStarted);
    return () => {
      removeEventListener('ResponseAudioStarted', handleOnResponseAudioStarted);
    };
  }, [addEventListener, handleOnResponseAudioStarted, removeEventListener]);

  const handleOnAvatarReady = useCallback(() => {
    console.log('set loading assets to false');
    setLoadingStage(false);
    onLoaded();
  }, [setLoadingStage, onLoaded]);

  useEffect(() => {
    addEventListener('AvatarReady', handleOnAvatarReady);
    return () => {
      removeEventListener('AvatarReady', handleOnAvatarReady);
    };
  }, [addEventListener, handleOnAvatarReady, removeEventListener]);

  const handlePatientFinishedTalking = useCallback(() => {
    onVirtualHumanFinishedTalking();
  }, [onVirtualHumanFinishedTalking]);

  useEffect(() => {
    addEventListener('PatientFinishedTalking', handlePatientFinishedTalking);
    return () => {
      removeEventListener(
        'PatientFinishedTalking',
        handlePatientFinishedTalking
      );
    };
  }, [addEventListener, handlePatientFinishedTalking, removeEventListener]);

  useEffect(() => {
    // send conversation item audio to unity to render
    if (audioToPlay) {
      onVirtualHumanStartTalking();
      try {
        sendMessage(
          'WebBridge',
          'SendBufferToUnity',
          JSON.stringify({ response: { audio: { url: audioToPlay.url } } })
        );
      } catch (e) {
        console.log(e);
        console.log('error when sending audio to Unity');
      }
    }
  }, [audioToPlay, onVirtualHumanStartTalking, sendMessage]);

  // We'll use a state to store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  useEffect(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener('change', updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  return (
    <Styled.UnityPlayerWrapper>
      {loadingStage !== false && (
        <UnityLoader
          loadingFlavourTexts={loadingFlavourTexts}
          progress={Math.round(loadingProgression * 100)}
          loadingStage={loadingStage}
        />
      )}
      <Unity
        devicePixelRatio={devicePixelRatio}
        unityProvider={unityProvider}
        className="unity-player"
      />
    </Styled.UnityPlayerWrapper>
  );
};

export default VHUnityPlayer;
