import { create } from 'zustand';
import { ChatStoreState, ChatType } from './ChatsStore.type';
import { File } from '../../types/File.type';

export const useChatsStore = create<ChatStoreState>((set) => ({
  chats: [],
  awaitingUnityAudioPlay: false,
  setAwaitingUnityAudioPlay: (newAwaitingUnityAudioPlay: boolean) =>
    set((state) => ({
      ...state,
      awaitingUnityAudioPlay: newAwaitingUnityAudioPlay,
    })),
  addChat: (chat: { chatType: ChatType; text: string; audio?: File }) =>
    set((state) => ({
      ...state,
      chats: [...state.chats, { id: state.chats.length, ...chat }],
    })),
  resetChat: () =>
    set((state) => ({
      ...state,
      chats: [],
    })),
}));

export const initialGreeting = 'Please greet me.';

type PreviousMessages = {
  question1?: string | undefined;
  answer1?: string | undefined;
  question2?: string | undefined;
  answer2?: string | undefined;
  question3?: string | undefined;
  answer3?: string | undefined;
};

export const usePreviousMessages = (): PreviousMessages => {
  const chats = useChatsStore((state) => state.chats);
  let userChats = chats.filter((chat) => chat.chatType === 'user');
  userChats = userChats.slice(-3);
  const botChats = chats.filter((chat) => chat.chatType === 'bot').slice(-3);
  return {
    question1: userChats[0] ? userChats[0].text : undefined,
    answer1: botChats[0] ? botChats[0].text : undefined,
    question2: userChats[1] ? userChats[1].text : undefined,
    answer2: botChats[1] ? botChats[1].text : undefined,
    question3: userChats[2] ? userChats[2].text : undefined,
    answer3: botChats[2] ? botChats[2].text : undefined,
  };
};
