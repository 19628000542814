import { create } from 'zustand';

export type UserVHData = {
  vhName: string;
  characterInformation: string;
  scenarioObjective?: string;
  openness?: number;
  aggressiveness?: number;
  chattiness?: number;
  confidence?: number;
  greeting: string;
  personalityTraits: string[];
  background: string;
};

type UserVHDataStore = UserVHData & {
  setUserVHData: (vhData: Partial<UserVHData>) => void;
};

export const useUserVHDataStore = create<UserVHDataStore>((set) => ({
  vhName: '',
  characterInformation: '',
  background: '',
  openness: 0,
  aggressiveness: 0,
  chattiness: 0,
  confidence: 0,
  greeting: '',
  personalityTraits: [],
  setUserVHData: (vhData: Partial<UserVHData>) =>
    set((_state) => ({
      ..._state,
      ...vhData,
    })),
}));
