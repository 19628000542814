import { useCallback, useContext, useEffect, useState } from 'react';
import { MicMutedContext } from '../../../../../contexts/MicMutedProvider/MicMutedProvider';
import { useVHStateStore } from '../../../../../stores/VHStateStore/VHStateStore';
import useDeepgram from '../../../../../utility/useDeepgram';
import { usePrevious } from '../../../../../utility/hooks/StateHooks';
import { useUpdateMicActive } from '../../../../../contexts/MicBehaviorProvider/useUpdateMicActive';
import RecordingButton from '../ChatInput/RecordingButton/RecordingButton';

const DeepgramButtonPushToTalk = ({
  onSubmit,
  onChange,
  textDisabled,
  recordingDisabled,
  onSpeechError,
  currentChat,
  onPendingTranscription,
}: {
  onSubmit: (chat: string) => void;
  onChange: (e: string) => void;
  textDisabled: boolean;
  recordingDisabled: boolean;
  onSpeechError: (err: string) => void;
  currentChat: string;
  onPendingTranscription: (pending: boolean) => void;
}) => {
  const { muted, setMuted } = useContext(MicMutedContext);
  const virtualHumanTalking = useVHStateStore((state) => state.isTalking);
  const isSendingChat = useVHStateStore((state) => state.isSendingChat);
  const showMutedNoticeIfTalked =
    muted && !virtualHumanTalking && !isSendingChat;
  const [recording, setRecording] = useState(false);
  const pauseDeepgram =
    muted || textDisabled || virtualHumanTalking || isSendingChat || !recording;
  // use debounced variable so that it doesn't flick between paused and unpaused in the moment that they change
  // const [debouncedPauseDeepgram] = useDebounce(pauseDeepgram, 50);
  const {
    currentUnfinishedAffirmation,
    currentAffirmation,
    finalAffirmation,
    error,
    connected,
    resetTranscript,
    connecting,
  } = useDeepgram({
    // language,
    paused: pauseDeepgram,
  });

  const prevRecordingDisabled = usePrevious(recordingDisabled);
  useEffect(() => {
    if (!recordingDisabled && prevRecordingDisabled) {
      console.log('resetTranscript');
      resetTranscript();
    }
  }, [recordingDisabled, prevRecordingDisabled, resetTranscript]);

  const [waitingToSubmit, setWaitingToSubmit] = useState(false);

  const pttSubmit = useCallback(
    (submissionText: string) => {
      onSubmit(submissionText);
      setMuted(true);
      resetTranscript();
      setWaitingToSubmit(false);
    },
    [onSubmit, resetTranscript, setMuted]
  );

  const prevFinalAffirmation = usePrevious(finalAffirmation);
  useEffect(() => {
    if (finalAffirmation !== prevFinalAffirmation && !pauseDeepgram && !muted) {
      const submissionText = !currentChat
        ? finalAffirmation
        : `${currentChat} ${finalAffirmation}`;
      onPendingTranscription(false);
      onChange(submissionText);
    }
  }, [
    currentChat,
    finalAffirmation,
    onChange,
    prevFinalAffirmation,
    pauseDeepgram,
    resetTranscript,
    muted,
    waitingToSubmit,
    pttSubmit,
    onPendingTranscription,
  ]);

  useEffect(() => {
    if (pauseDeepgram) {
      setRecording(false);
    }
  }, [pauseDeepgram]);

  useEffect(() => {
    if (currentAffirmation || currentUnfinishedAffirmation) {
      onPendingTranscription(true);
    }
  }, [
    currentAffirmation,
    currentUnfinishedAffirmation,
    onPendingTranscription,
  ]);

  const handleRecordingButtonClick = useCallback(() => {
    if (recording) {
      setMuted(true);
    } else {
      setMuted(false);
    }
    onPendingTranscription(false);
    setRecording(!recording);
  }, [setRecording, setMuted, onPendingTranscription, recording]);

  useEffect(() => {
    if (error) {
      onSpeechError(error);
    }
  }, [error, onSpeechError]);

  useUpdateMicActive(recording);

  return (
    <RecordingButton
      disabled={recordingDisabled || waitingToSubmit}
      onClick={handleRecordingButtonClick}
      muted={muted}
      connected={connected}
      showMutedNoticeIfTalked={showMutedNoticeIfTalked}
      networkError={!connected && error}
      recording={recording}
      connecting={connecting}
    />
  );
};

export default DeepgramButtonPushToTalk;
