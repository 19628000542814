import { useState, useRef, useEffect } from 'react';
import { GetFormattedTime } from '../../../utility/GetFormattedTime';

export const useStopwatchTimer = (
  startTime: number,
  totalTimeSeconds: number,
  callback: () => void
) => {
  const [timerState, setTimerState] = useState({
    running: false,
    progress: 0,
    msRemaining: totalTimeSeconds * 1000,
    formattedTimeRemaining: GetFormattedTime(totalTimeSeconds / 1000),
    finished: false,
  });
  const running = useRef(false);

  useEffect(() => {
    running.current = timerState.running;
  }, [timerState.running]);

  useEffect(() => {
    if (!running.current) return;

    const timeoutCallback = () => {
      if (!running.current) return;
      const curTime = new Date().getTime();
      const endTime = startTime + totalTimeSeconds * 1000;
      const msRemaining = endTime - curTime;
      // const secondsRemaining = Math.round(msRemaining / 1000);
      if (
        startTime &&
        totalTimeSeconds &&
        msRemaining < 0 &&
        !timerState.finished
      ) {
        // timer finished
        setTimerState((t) => {
          return {
            ...t,
            ...{
              finished: true,
              running: false,
              msRemaining: 0,
              progress: 100,
              formattedTimeRemaining: GetFormattedTime(
                timerState.running
                  ? Math.max(0, msRemaining)
                  : totalTimeSeconds * 1000
              ),
            },
          };
        });
        callback();
      } else {
        // only update if minimum time has passed
        const minMsPassedToUpdate = 1000;
        if (
          Math.abs(timerState.msRemaining - msRemaining) >= minMsPassedToUpdate
        ) {
          setTimerState((t) => {
            return {
              ...t,
              ...(t.running && {
                msRemaining,
                progress:
                  ((totalTimeSeconds * 1000 - msRemaining) /
                    (totalTimeSeconds * 1000)) *
                  100,
                formattedTimeRemaining: GetFormattedTime(
                  timerState.running
                    ? Math.max(0, msRemaining)
                    : totalTimeSeconds * 1000
                ),
              }),
            };
          });
        }
        // timeout lower than 1000 since float comparison might lead to some updates to timer not updating in proper time sync
        const timer: number = setTimeout(timeoutCallback, 50);
        return timer;
      }
    };
    const timer = timeoutCallback();
    return () => {
      clearTimeout(timer);
    };
  }, [
    callback,
    startTime,
    totalTimeSeconds,
    timerState.finished,
    timerState.running,
    timerState.msRemaining,
  ]);

  return {
    timerState,
    startTimer: () =>
      setTimerState((t) => {
        return { ...t, running: true };
      }), // startTimer function
    stopTimer: () =>
      setTimerState((t) => {
        return {
          ...t,
          running: false,
        };
      }), // stopTimer function
  };
};
