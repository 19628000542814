import styled, { keyframes } from 'styled-components';
import { BaseBubble } from '../ChatItem/ChatBubble/ChatBubble.styled';

const bounceAnim = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-8px);
  }
`;

export const LoadingIndicatorWrapper = styled(BaseBubble)`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px black;
  font-style: italic;
  color: ${(props) => props.theme.color.contrast.base};
`;

export const LoadingIndicator = styled.div`
  font-style: normal;
  display: flex;
  gap: 2px;
  font-size: 1.6rem;
  > span {
    display: inline-block;
    position: relative;
    bottom: 8px;
    animation-duration: 0.4s;
    animation-name: ${bounceAnim};
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  > span:nth-child(2) {
    animation-delay: 0.1s;
  }
  > span:nth-child(3) {
    animation-delay: 0.2s;
  }
`;
