import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useChatsStore,
  usePreviousMessages,
} from '../../../stores/ChatsStore/ChatsStore';
import ChatItem from './ChatItem/ChatItem';
import VHTextChatInput from './VHTextChatInput/VHTextChatInput';
import * as Styled from './Chat.styled';
import Axios from 'axios';
import { useUserVHDataStore } from '../../../stores/UserVHDataStore/UserVHDataStore';
import { useSelectedTemplateStore } from '../../../stores/SelectedTemplateStore/SelectedTemplateStore';
import ChatLoadingIndicator from './ChatLoadingIndicator/ChatLoadingIndicator';
import { useVHStateStore } from '../../../stores/VHStateStore/VHStateStore';
import { Button } from '@virtidev/toolbox';
import { track, trackCtaClick } from '../../../utility/Tracking';
import { getModeUrl } from '../../../utility/RouteUtils';
import MicrophoneStreamProvider from '../../../contexts/MicrophoneStreamProvider/MicrophoneStreamProvider';
import MicBehaviorProvider from '../../../contexts/MicBehaviorProvider/MicBehaviorProvider';
import MicMutedProvider from '../../../contexts/MicMutedProvider/MicMutedProvider';

const Chat = ({
  unityLoaded,
  disabled,
}: {
  unityLoaded: boolean;
  disabled: boolean;
}) => {
  const chats = useChatsStore((state) => state.chats);
  const previousMessages = usePreviousMessages();
  const [chatInput, setChatInput] = useState('');
  const vhData = useUserVHDataStore();
  const voice = useSelectedTemplateStore(
    (state) => state.selectedTemplate?.voice
  );
  const greeting = useSelectedTemplateStore(
    (state) => state.selectedTemplate?.greeting
  );

  const [isAwaitingTranscriptionToSend, setIsAwaitingTranscriptionToSend] =
    useState(false);
  const addChat = useChatsStore((state) => state.addChat);
  const isSendingChat = useVHStateStore((state) => state.isSendingChat);
  const awaitingUnityAudioPlay = useChatsStore(
    (state) => state.awaitingUnityAudioPlay
  );
  const setSendingChat = useVHStateStore((state) => state.setSendingChat);

  const setAwaitingUnityAudioPlay = useChatsStore(
    (state) => state.setAwaitingUnityAudioPlay
  );

  const setAudioToPlay = useVHStateStore((state) => state.setAudioToPlay);

  const setTimeStarted = useVHStateStore((state) => state.setTimeStarted);

  const [resendData, setResendData] = useState<{
    chatToSend: string;
    isGreeting: boolean;
  } | null>(null);

  const chatSessionId = useVHStateStore((state) => state.chatSessionId);
  const setChatSessionId = useVHStateStore((state) => state.setChatSessionId);

  const sendChat = useCallback(
    async (chatToSend: string, isGreeting = false) => {
      setSendingChat(true);
      try {
        const res = await Axios.post(
          `${import.meta.env.VITE_END_POINT}demo-virtual-human`,
          {
            newChat: chatToSend,
            name: vhData.vhName,
            characterInformation: vhData.characterInformation,
            background: vhData.background,
            scenarioObjective: vhData.scenarioObjective,
            previousMessages,
            voice,
            greeting: isGreeting ? greeting : undefined,
            openness: vhData.openness,
            confidence: vhData.confidence,
            aggressiveness: vhData.aggressiveness,
            chattiness: vhData.chattiness,
            personalityTraits: vhData.personalityTraits,
            chatSessionId,
          }
        );
        // if it's the first user chat then start timer
        if (chats.length === 1) {
          setTimeStarted(new Date().getTime());
        }
        setAwaitingUnityAudioPlay(true);
        addChat({
          chatType: 'bot',
          text: res?.data?.data?.text,
          audio: res?.data?.data?.audio,
        });
        if (greeting && res.data?.data?.chatSessionId) {
          setChatSessionId(res.data.data.chatSessionId);
        }
        if (res?.data?.data?.audio) {
          setAudioToPlay(res.data.data.audio);
        }
      } catch (e) {
        setResendData({ chatToSend, isGreeting });
      }
      setSendingChat(false);
    },
    [
      addChat,
      chatSessionId,
      chats.length,
      greeting,
      previousMessages,
      setAudioToPlay,
      setAwaitingUnityAudioPlay,
      setChatSessionId,
      setSendingChat,
      setTimeStarted,
      vhData,
      voice,
    ]
  );

  const greetingSent = useRef(false);
  useEffect(() => {
    if (!disabled && !greetingSent.current) {
      greetingSent.current = true;
      sendChat('greeting', true);
    }
  }, [disabled, greetingSent, sendChat]);

  const handleChange = useCallback(
    (newString: string) => {
      if (newString.indexOf('\n') > -1) {
        return;
      }
      // if we're awaiting a PTT transcription to finish (a hacky check based on the length of string change)
      const isMicTranscriptionChange = newString.length - chatInput.length > 1;
      if (isAwaitingTranscriptionToSend && isMicTranscriptionChange) {
        setIsAwaitingTranscriptionToSend(false);
        sendChat(newString);
        setChatInput('');
        addChat({ chatType: 'user', text: newString });
      } else {
        setChatInput(newString);
      }
    },
    [chatInput.length, isAwaitingTranscriptionToSend, sendChat, addChat]
  );

  const chatLogRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (chatLogRef && chatLogRef.current) {
      chatLogRef.current.scrollTo(0, chatLogRef.current.scrollHeight);
    }
  }, [chats]);

  const chatLimitReached = chats.length > 100;

  const virtualHumanTalking = useVHStateStore((state) => state.isTalking);
  const textDisabled = isSendingChat || disabled || chatLimitReached;
  const recordingDisabled =
    textDisabled || virtualHumanTalking || isSendingChat;

  const setSessionComplete = useVHStateStore(
    (state) => state.setSessionComplete
  );
  const sessionComplete = useVHStateStore((state) => state.sessionComplete);
  const setSessionCompleteReason = useVHStateStore(
    (state) => state.setSessionCompleteReason
  );

  useEffect(() => {
    if (chatLimitReached) {
      console.log('helloooooooooooooooo');
      setSessionCompleteReason('reached chat limit');
      setSessionComplete(true);
    }
  }, [chatLimitReached, setSessionComplete, setSessionCompleteReason]);

  useEffect(() => {
    if (chats.length) {
      track('chat_sent', { number: chats.length });
    }
  }, [chats.length]);

  const handleResend = useCallback(() => {
    if (!resendData) {
      return;
    }
    sendChat(resendData.chatToSend, resendData.isGreeting);
    setResendData(null);
  }, [resendData, sendChat]);

  const trackTrialClick = useCallback(
    () => trackCtaClick('free_trial', 'end_chat'),
    []
  );
  const trackDemoClick = useCallback(
    () => trackCtaClick('book_demo', 'end_chat'),
    []
  );

  let sendingText =
    chats && chats.length > 0 && chats[chats.length - 1].chatType === 'user'
      ? chats[chats.length - 1].text
      : '';
  if (
    !sendingText &&
    chats &&
    chats.length > 1 &&
    chats[chats.length - 2].chatType === 'user'
  ) {
    sendingText = chats[chats.length - 2].text;
  }

  const [isPendingTranscription, setIsPendingTranscription] = useState(false);
  const handlePendingTranscription = useCallback((pending: boolean) => {
    console.log(pending);
    setIsPendingTranscription(pending);
  }, []);

  const handleSubmit = useCallback(
    (chat: string) => {
      console.log('handleSubmit');
      console.log(chat);
      if (isPendingTranscription) {
        console.log('isPendingTranscription');
        setIsAwaitingTranscriptionToSend(true);
      } else if (chat !== '') {
        console.log('sendChat');
        console.log(chat);
        sendChat(chat);
        setChatInput('');
        addChat({ chatType: 'user', text: chat });
      }
    },
    [sendChat, isPendingTranscription, addChat]
  );
  const submitDisabled =
    textDisabled ||
    virtualHumanTalking ||
    isSendingChat ||
    (chatInput === '' && !isPendingTranscription) ||
    isAwaitingTranscriptionToSend ||
    chatLimitReached;

  return (
    <Styled.Chat>
      <Styled.ChatLog ref={chatLogRef}>
        {/* {chats.map((chat) => (
          <ChatItem chatLog={chat} key={chat.id} />
        ))} */}
        {!chatLimitReached &&
          !sessionComplete &&
          chats.length > 0 &&
          !awaitingUnityAudioPlay &&
          !isSendingChat && <ChatItem chatLog={chats[chats.length - 1]} />}
        {(isSendingChat || awaitingUnityAudioPlay) && (
          <ChatLoadingIndicator sendingText={sendingText} />
        )}
        {resendData && (
          <Styled.ResendWrapper>
            Error accessing server.{' '}
            <Button onClick={handleResend}>Resend</Button>
          </Styled.ResendWrapper>
        )}
        {(chatLimitReached || sessionComplete) &&
          !awaitingUnityAudioPlay &&
          !isSendingChat && (
            <ChatItem
              chatLog={{
                text: '',
                chatType: 'virti',
                id: 0,
              }}
            >
              <>
                We hope you enjoyed this free role-play training scenario
                demonstration! To find out more,{' '}
                <a
                  href={getModeUrl('book_demo')}
                  target="_blank"
                  onClick={trackDemoClick}
                  aria-label="Opens a new tab"
                >
                  Book a demo with us
                </a>{' '}
                or{' '}
                <a
                  href="https://app.virti.com/register"
                  target="_blank"
                  onClick={trackTrialClick}
                  aria-label="Opens a new tab"
                >
                  create a free trial account
                </a>{' '}
                to explore our platform!
              </>
            </ChatItem>
          )}
      </Styled.ChatLog>
      {unityLoaded && (
        <Styled.InputArea>
          <MicrophoneStreamProvider>
            <MicBehaviorProvider>
              <MicMutedProvider>
                <VHTextChatInput
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                  chat={chatInput}
                  textDisabled={textDisabled}
                  inputDisabledText={
                    sessionComplete ? 'Session complete.' : 'Loading...'
                  }
                  submitDisabled={submitDisabled}
                  recordingDisabled={recordingDisabled}
                  maxLength={512}
                  onPendingTranscription={handlePendingTranscription}
                />
              </MicMutedProvider>
            </MicBehaviorProvider>
          </MicrophoneStreamProvider>
        </Styled.InputArea>
      )}
    </Styled.Chat>
  );
};

export default Chat;
