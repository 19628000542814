import { create } from "zustand";
import { SelectedTemplateStoreState } from "./SelectedTemplateStore.type";
import { PlayerTemplate } from "../../types/PlayerTemplate.type";

export const useSelectedTemplateStore = create<SelectedTemplateStoreState>(
  (set) => ({
    selectedTemplate: null,
    setSelectedTemplate: (newTemplate: PlayerTemplate) =>
      set((_state) => ({ selectedTemplate: newTemplate })),
  })
);
