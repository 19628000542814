import * as Styled from './ChatLoadingIndicator.styled';

const ChatLoadingIndicator = ({ sendingText }: { sendingText: string }) => {
  return (
    <Styled.LoadingIndicatorWrapper>
      {sendingText && <span>{sendingText}</span>}
      <Styled.LoadingIndicator>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </Styled.LoadingIndicator>
    </Styled.LoadingIndicatorWrapper>
  );
};

export default ChatLoadingIndicator;
