export function getModeUrl(mode: 'book_demo' | '') {
  const url = new URL(window.location.href);
  if (mode) {
    url.searchParams.set('mode', mode);
    return url.toString();
  } else {
    url.searchParams.delete('mode');
    return url.toString();
  }
}
