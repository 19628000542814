import styled from 'styled-components';

export const ChatInput = styled.input`
  border: none;
  resize: none;
  background: none;
  flex: 1;
  font-size: 1rem;
  pointer-events: all;
  outline: none;
  height: 68px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ::placeholder {
    color: ${(props) => props.theme.color.darkGrey};
  }
`;
