import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import 'regenerator-runtime';
import ChatInput from './ChatInput/ChatInput';
import * as Styled from './VHTextChatInput.styled';
// import { useVHStateStore } from '../../../../stores/VHStateStore/VHStateStore';
import { useChatsStore } from '../../../../stores/ChatsStore/ChatsStore';
// import RecordingText from './RecordingText/RecordingText';
import { IsMobile } from '../../../../utility/BrowserChecks';
import DeepgramButton from './DeepgramButton/DeepgramButton';
import { MicBehaviorContext } from '../../../../contexts/MicBehaviorProvider/MicBehaviorProvider';
import DeepgramButtonPushToTalk from './DeepgramButtonPushToTalk/DeepgramButtonPushToTalk';
import MicBehaviorSelector from './MicBehaviorSelector/MicBehaviorSelector';

const VHTextChatInput = ({
  onSubmit,
  onChange,
  chat,
  textDisabled,
  inputDisabledText,
  submitDisabled,
  recordingDisabled,
  maxLength,
  onPendingTranscription,
}: {
  onSubmit: (chat: string) => void;
  onChange: (chat: string) => void;
  chat: string;
  textDisabled: boolean;
  inputDisabledText: string;
  submitDisabled: boolean;
  recordingDisabled: boolean;
  maxLength: number;
  onPendingTranscription: (pending: boolean) => void;
}) => {
  // const { isTalking } = useVHStateStore();
  // // const { selectedTemplate } = useSelectedTemplateStore();
  // // const localeCode = selectedTemplate?.localeCode ?? 'en-US';
  const chatInputRef = useRef<HTMLInputElement>(null);
  // // if mic stops listening and a transcript exists, submit that newly spoken text
  // const isSendingChat = useVHStateStore((state) => state.isSendingChat);
  // // const prevListening = usePrevious(listening);
  // const sessionComplete = useVHStateStore((state) => state.sessionComplete);

  const { chats } = useChatsStore();
  // focus input when chat length changes
  useEffect(() => {
    if (chatInputRef?.current) {
      if (IsMobile()) {
        return;
      }
      chatInputRef.current.focus();
    }
  }, [chats?.length]);

  const handleSubmitButton = () => {
    onSubmit(chat);
    setSpeechError(null);
  };
  const { micBehavior } = useContext(MicBehaviorContext);

  const handleChatChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );
  const [_speechError, setSpeechError] = useState<string | null>(null); // null | 'noSpeechDetected' | 'speechTooLong'
  const handleOnSpeechError = useCallback((err: string) => {
    setSpeechError(err);
  }, []);

  return (
    <Styled.ChatInputWrapper>
      <ChatInput
        value={chat}
        disabled={textDisabled}
        onChange={handleChatChange}
        placeholder={
          textDisabled ? inputDisabledText : `Start typing or record...`
        }
        ref={chatInputRef}
        onEnter={() => {
          if (submitDisabled) return;
          setSpeechError(null);
          onSubmit(chat);
        }}
        maxLength={maxLength}
      />
      <Styled.ExtraWrapper>
        {micBehavior === 'open' ? (
          <DeepgramButton
            onChange={onChange}
            recordingDisabled={recordingDisabled}
            textDisabled={textDisabled}
            onSubmit={onSubmit}
            onSpeechError={handleOnSpeechError}
          />
        ) : (
          <DeepgramButtonPushToTalk
            onChange={onChange}
            recordingDisabled={recordingDisabled}
            textDisabled={textDisabled}
            onSubmit={onSubmit}
            onSpeechError={handleOnSpeechError}
            currentChat={chat}
            onPendingTranscription={onPendingTranscription}
          />
        )}
        <MicBehaviorSelector disabled={recordingDisabled || textDisabled} />

        {/* {!isIOS() && (
          <RecordingButton
            onStartRecording={handleOnStartRecording}
            onStopRecording={handleOnStopRecording}
            disabled={recordingDisabled}
            onMicUnavailableOnClick={handleMicUnavailableOnClick}
            localeCode={localeCode}
            listening={listening}
          />
        )} */}
        <Styled.RecordingTextWrapper>
          {/* <RecordingText
            noSpeechDetected={speechError === 'noSpeechDetected'}
            speechTooLong={speechError === 'speechTooLong'}
            listening={listening}
            showMicPermissionsWarning={showMicPermissionsWarning}
          /> */}
        </Styled.RecordingTextWrapper>
        <Styled.SubmitWrapper>
          <Styled.SubmitButton
            onClick={handleSubmitButton}
            disabled={submitDisabled}
            color="turquoise"
            size="small"
            icon="arrow"
          ></Styled.SubmitButton>
        </Styled.SubmitWrapper>
      </Styled.ExtraWrapper>
    </Styled.ChatInputWrapper>
  );
};

export default VHTextChatInput;
