import { Button, Loading } from '@virtidev/toolbox';
import styled, { keyframes } from 'styled-components';

export const SpeechToTextWrapper = styled.div`
  position: relative;
  grid-column-gap: 4px;
  justify-content: center;
  align-items: center;
`;

export const AffirmationSpeech = styled.div`
  position: absolute;
  bottom: 3rem;
  box-shadow: var(--card-box-shadow);
  display: flex;
  align-items: center;
  right: 0;
  background-color: ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.contrast.secondary};
  padding: 0.5rem 1rem;
  border-radius: 16px 16px 0 16px;
  width: 300px;
  z-index: 1;
`;

export const RecordingButton = styled(Button).attrs({
  color: 'turquoise',
  design: 'transparent',
})`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  cursor: pointer;
  padding: 10px;
  // stop mobile from touch activating the hover color
  @media (pointer: coarse), (pointer: none) {
    &:hover {
      background: initial;
    }
  }
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 60px;
  background-color: ${({ theme }) => theme.color.success};
`;

export const StopRecordingButton = styled(RecordingButton)`
  font-size: 16px;
`;

const pulsate = keyframes`
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
`;

export const PulsingBorder = styled.div`
  position: absolute;
  top: -3px;
  width: 40px;
  height: 40px;
  animation: ${pulsate} 1s ease-out infinite;
  transform: translate(-50% -50%);
  border: 5px solid ${(props) => props.theme.color.secondary};
  border-radius: 8px;
  opacity: 0.3;
`;
export const ConnectionError = styled.div`
  position: absolute;
  bottom: -20px;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  cursor: help;
`;
export const Loader = styled(Loading)``;

export const DismissableContent = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export const DismissForever = styled.button`
  border: none;
  display: inline-block;
  background: none;
  text-align: center;
  font-size: 0.8rem;
  padding: 0;
  cursor: pointer;
  color: ${(props) => props.theme.color.primaryDark};
  :hover {
    text-decoration: underline;
  }
`;
