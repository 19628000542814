import styled, { css } from 'styled-components';

export const MOBILE_PLAYER_SIZE = '50svh';

export const VHPlayer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const MainArea = styled.div``;

export const UIOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  pointer-events: none;
  button {
    pointer-events: all;
  }
  /* @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  } */
`;

export const CornerUI = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  gap: 1rem;
`;

export const Timer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 5rem;
  padding: 1.5rem 1.5rem 0 0;
`;

export const ChatSectionWrapper = styled.div<{ $unityLoaded: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 3;
  justify-content: flex-end;
  ${(props) =>
    !props.$unityLoaded &&
    css`
      opacity: 0;
    `}/* @media (max-width: 800px) {
    justify-content: initial;
    flex: 1;
  } */
`;
