import { HelpLabel } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const FormLabel = styled(HelpLabel)<{ $locked?: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  margin-bottom: 9px;
  color: ${({ theme: { color } }) => color.midnight};
  display: flex;
  ${(props) =>
    props.$locked &&
    css`
      display: flex;
      gap: 0.3rem;
    `}
`;

export const TooltipWrapper = styled.div`
  margin-left: auto;
  font-size: 1.5rem;
`;
