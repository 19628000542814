import { Button } from '@virtidev/toolbox';
import { useVHStateStore } from '../../stores/VHStateStore/VHStateStore';
import * as Styled from './CTAModal.styled';
import { getModeUrl } from '../../utility/RouteUtils';
import { useCallback } from 'react';
import { trackCtaClick } from '../../utility/Tracking';

const CTAModalContent = ({ onRestart }: { onRestart: () => void }) => {
  const ctaModalActiveType = useVHStateStore(
    (state) => state.ctaModalActiveType
  );

  const trackLocation = ctaModalActiveType === 'finish' ? 'end_modal' : '';
  const trackDemoClick = useCallback(() => {
    if (trackLocation) trackCtaClick('book_demo', trackLocation);
  }, [trackLocation]);

  return (
    <Styled.Wrapper>
      {ctaModalActiveType === 'booking' && (
        <>
          <Styled.Heading>
            Want to replicate real-world conversations at scale today?
          </Styled.Heading>
          <Styled.Paragraph>
            Book a free live demo to discover the full capabilities of our
            Virtual Humans. Learn how to customise language, avatar, environment
            and more, and talk to an expert about how Virti immersive training
            products can meet your specific needs.
          </Styled.Paragraph>
        </>
      )}
      {ctaModalActiveType === 'finish' && (
        <>
          <Styled.Heading>
            Ready to unlock limitless possibilities?
          </Styled.Heading>
          <Styled.Paragraph>
            Experience more than just virtual role-play scenarios. Our
            cutting-edge platform goes beyond AI-powered virtual humans,
            offering interactive 360-degree video, game technology, and
            performance analytics. Your organization will be empowered to create
            and scale unique learning experiences quickly. Ready to see what's
            possible?
          </Styled.Paragraph>
        </>
      )}
      <Styled.CtaWrapper>
        <Button
          as="a"
          color="turquoise"
          // icon="expand"
          onClick={trackDemoClick}
          href={getModeUrl('book_demo')}
          aria-label="Opens a new tab"
          target="_blank"
        >
          Book a demo
        </Button>
        {ctaModalActiveType === 'finish' && (
          <Styled.SecondaryCta type="submit" onClick={onRestart}>
            Fancy trying a different virtual role-play scenario?
          </Styled.SecondaryCta>
        )}
      </Styled.CtaWrapper>
    </Styled.Wrapper>
  );
};

export default CTAModalContent;
