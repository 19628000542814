import * as Styled from './Steps.styled';
import { ProgressBar } from '@virtidev/toolbox';

const Steps = ({
  totalSteps,
  currentStep,
  isMobile,
}: {
  totalSteps: number;
  currentStep: number;
  isMobile?: boolean;
}) => {
  return (
    <Styled.StepsWrapper $isMobile={isMobile}>
      <Styled.StepText>Step {currentStep}</Styled.StepText>
      <ProgressBar progress={currentStep} max={totalSteps} />
    </Styled.StepsWrapper>
  );
};

export default Steps;
