import styled, { css } from 'styled-components';

export const TemplateButton = styled.button<{ $active: boolean }>`
  border: none;
  background: none;
  cursor: pointer;
  flex: 1;
  color: ${({ theme: { color } }) => color.midnight10};
  background: #fff;
  ${(props) =>
    props.$active &&
    css`
      outline: 3px solid ${(props) => props.theme.color.pink};
      background-color: ${({ theme: { color } }) => color.pink30};
    `}
  &:hover {
    background-color: ${({ theme: { color } }) => color.pink30};
  }
  text-align: left;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: ${(props) => props.theme.color.primaryDark};
  max-width: 600px;
  img {
    display: inline-block;
    width: 100%;
  }
  box-shadow: 0 4px 4px 0 #e1e1e5;
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  gap: 1rem;
  justify-content: space-between;
`;

export const TemplateContent = styled.div`
  padding: 1.25rem;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
`;
export const TemplateTypeText = styled.h2<{ $active: boolean }>`
  font-weight: 500;
  font-size: 19px;
  line-height: 19px;
  color: ${(props) => props.theme.color.midnight};
`;
export const TemplateScenarioText = styled.div``;

export const TickWrapper = styled.div<{ $active: boolean }>`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
