import * as Sentry from '@sentry/react';

const getEnvironment = () => {
  if (
    /deploy-preview-[0-9]+--virti-virtual-human-marketing-demo.netlify.app/.test(
      window.location.hostname
    )
  ) {
    return 'production-preview';
  }
  return 'production';
};

console.log(import.meta.env.PROD);
if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_ENDPOINT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
    ],
    environment: getEnvironment(),
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    sendDefaultPii: true,
  });
}
