import { useRef, useState, useCallback, useEffect } from 'react';
import Chat from './Chat/Chat';
import { useSelectedTemplateStore } from '../../stores/SelectedTemplateStore/SelectedTemplateStore';
import { useVHStateStore } from '../../stores/VHStateStore/VHStateStore';
import VHUnityPlayer from './VHUnityPlayer/VHUnityPlayer';
import * as Styled from './VHPlayer.styled';
import WebPlayerTimer from './WebPlayerTimer/WebPlayerTimer';
import Header from '../Header/Header';
import { Main } from '../../App.styled';
import StartModal from './StartModal/StartModal';
import { trackPlayerCompletion } from '../../utility/Tracking';
import { useUserVHDataStore } from '../../stores/UserVHDataStore/UserVHDataStore';
import { useChatsStore } from '../../stores/ChatsStore/ChatsStore';
import VHWebModalBase from '../VHWebModalBase/VHWebModalBase';

const VHPlayer = ({ onRestart }: { onRestart: () => void }) => {
  // set here since PDUnity isn't a functional component D:
  const wrapperRef = useRef(null);
  const template = useSelectedTemplateStore((state) => state.selectedTemplate);

  const [loaded, setLoaded] = useState(false);
  const [started, setStarted] = useState(false);
  const handleLoaded = () => {
    setLoaded(true);
  };
  const isTalking = useVHStateStore((state) => state.isTalking);
  const setTalking = useVHStateStore((state) => state.setTalking);

  // these two have to be useCallbacks to avoid endless talk repetitions from useEffect
  const handleVHStartTalking = useCallback(() => {
    setTalking(true);
  }, [setTalking]);
  const handleVHFinishTalking = useCallback(() => {
    setTalking(false);
  }, [setTalking]);

  const isSendingChat = useVHStateStore((state) => state.isSendingChat);
  const audioToPlay = useVHStateStore((state) => state.audioToPlay);

  const setSessionComplete = useVHStateStore(
    (state) => state.setSessionComplete
  );
  const sessionComplete = useVHStateStore((state) => state.sessionComplete);
  console.log('sessionComplete');
  console.log(sessionComplete);
  const setSessionCompleteReason = useVHStateStore(
    (state) => state.setSessionCompleteReason
  );
  const sessionCompleteReason = useVHStateStore(
    (state) => state.sessionCompleteReason
  );
  const setCtaModalVisible = useVHStateStore(
    (state) => state.setCtaModalActiveType
  );
  const markedToFinishOnVHFinishTalking = useRef(false);
  const handleTimerComplete = useCallback(() => {
    setSessionCompleteReason('reached time limit');
    if (isSendingChat || isTalking) {
      markedToFinishOnVHFinishTalking.current = true;
    } else {
      setSessionComplete(true);
    }
  }, [
    isTalking,
    isSendingChat,
    markedToFinishOnVHFinishTalking,
    setSessionComplete,
    setSessionCompleteReason,
  ]);

  useEffect(() => {
    if (markedToFinishOnVHFinishTalking.current && !isTalking) {
      setSessionComplete(true);
    }
  }, [isTalking, setSessionComplete]);

  const startTime = useVHStateStore((state) => state.timeStarted);
  const handleStartClick = useCallback(() => {
    setStarted(true);
  }, []);

  const vhData = useUserVHDataStore();
  const chats = useChatsStore((state) => state.chats);

  // Try to track session complete when tab is switched/closed.
  // This could potentially fire more than once so we should
  // treat instances of this event with a grain of salt.
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        trackPlayerCompletion(
          vhData,
          template,
          chats.length,
          'tab switched/closed',
          true
        );
      }
    };
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [chats.length, template, vhData]);

  useEffect(() => {
    if (sessionComplete) {
      setCtaModalVisible('finish');
      trackPlayerCompletion(
        vhData,
        template,
        chats.length,
        sessionCompleteReason,
        false
      );
    }
  }, [
    sessionComplete,
    chats?.length,
    sessionCompleteReason,
    setCtaModalVisible,
    template,
    vhData,
  ]);

  return (
    <>
      <VHWebModalBase />
      <Header onRestart={onRestart} />
      <Main id="main">
        <Styled.VHPlayer ref={wrapperRef}>
          {template && (
            <VHUnityPlayer
              audioToPlay={audioToPlay}
              onVirtualHumanFinishedTalking={handleVHFinishTalking}
              onVirtualHumanStartTalking={handleVHStartTalking}
              template={template}
              onLoaded={handleLoaded}
            />
          )}
          <Styled.UIOverlay>
            <Styled.CornerUI></Styled.CornerUI>
            <Styled.Timer>
              {!!startTime && (
                <div>
                  <WebPlayerTimer
                    onComplete={handleTimerComplete}
                    startTime={startTime}
                  />
                </div>
              )}
            </Styled.Timer>
            <Styled.ChatSectionWrapper $unityLoaded={loaded}>
              <Chat
                unityLoaded={loaded}
                disabled={!loaded || !started || sessionComplete}
              />
            </Styled.ChatSectionWrapper>
          </Styled.UIOverlay>
        </Styled.VHPlayer>
      </Main>
      <StartModal
        loaded={loaded}
        started={started}
        onStart={handleStartClick}
      />
    </>
  );
};

export default VHPlayer;
