import * as Styled from './SectionHeader.styled';
import { useIsMobileFooterLayout } from '../../utility/hooks/LayoutHooks';
import Steps from '../Steps/Steps';

const SectionHeader = ({
  totalSteps,
  currentStep,
  title,
}: {
  totalSteps: number;
  currentStep: number;
  title: string;
}) => {
  const isMobileLayout = useIsMobileFooterLayout();

  return (
    <Styled.SectionHeaderWrapper>
      {isMobileLayout && (
        <Styled.StepsWrapper>
          <Steps
            isMobile={true}
            totalSteps={totalSteps}
            currentStep={currentStep}
          />
        </Styled.StepsWrapper>
      )}
      <Styled.SectionHeading>{title}</Styled.SectionHeading>
    </Styled.SectionHeaderWrapper>
  );
};

export default SectionHeader;
