import styled from 'styled-components';

export const StepsWrapper = styled.div<{ $isMobile?: boolean }>`
  display: grid;
  align-items: center;
  font-weight: 700;
  grid-template-columns: ${({ $isMobile }) =>
    $isMobile ? '60px 1fr' : '60px 200px'};
`;

export const StepText = styled.div`
  margin-bottom: 2px;
`;
