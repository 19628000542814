import styled from 'styled-components';
import { linkCss } from '../../App.styled';
import { Modal } from '@virtidev/toolbox';

export const StyledCTAModal = styled(Modal)`
  .modal-box {
    max-width: 660px;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 54px 32px 54px;
  }
`;

export const Wrapper = styled.div`
  text-align: left;
`;

export const Heading = styled.h2`
  color: var(--blue-grey);
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  line-height: 135%; /* 48.6px */
  letter-spacing: -0.36px;
  max-width: 18em;
  color: ${({ theme: { color } }) => color.midnight};
  font-size: 28px;
  font-weight: 500;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  ${({ theme: { color } }) => color.midnight10};
  margin: 1rem 0;
`;

export const IframeWrapper = styled.div<{ $visible: boolean }>`
  // Hiding instead of unmounting so we don't lose state if screen is resized accidentally
  display: ${(props) => (props.$visible ? 'block' : 'none')};
  iframe {
    width: 100%;
    height: 720px;
    border: none;
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  flex-direction: column;
`;

export const SecondaryCta = styled.button`
  all: unset;
  color: ${(props) => props.theme.color.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  display: block;
  cursor: pointer;
  ${linkCss}
`;
