import styled from 'styled-components';
import { SectionWrapper } from '../VHForms/VHForms.styled';

export const HeaderWrapper = styled(SectionWrapper)`
  border-top: 1px solid var(--header-border-color);
  background-color: #fff;
`;

export const StepBanner = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 22px;
`;

export const BackWrapper = styled.div`
  margin-left: auto;
`;
