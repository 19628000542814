import { useVHWebModalContext } from './VHWebModalBaseContext';

const VHWebModalBase = () => {
  const vhWebModalRef = useVHWebModalContext();
  return (
    <div
      style={{ position: 'absolute' }}
      ref={vhWebModalRef as React.RefObject<HTMLDivElement>}
    ></div>
  );
};

export default VHWebModalBase;
