import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';

type ProviderValue = {
  rawStream: MediaStream | null;
  stream: MediaStream | null;
  error: any;
  setVolume: (vol: number) => void;
  startStream: () => void;
};
export const MicrophoneStreamContext = createContext<ProviderValue>({
  rawStream: null,
  stream: null,
  error: null,
  setVolume: (_vol: number) => {},
  startStream: () => {},
});

export const MicrophoneStreamProvider = ({
  children,
}: {
  children: React.ReactNode | string;
}) => {
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [rawStream, setRawStream] = useState<MediaStream | null>(null);
  const [gainNode, setGainNode] = useState<GainNode | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [error, setError] = useState<any>(null);

  const startStream = useCallback(async () => {
    return new Promise((resolve, reject) => {
      try {
        const _userMedia = navigator.mediaDevices.getUserMedia;
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            msg: 'Failed to access getUserMedia',
          },
        });
        console.error('error starting mic stream:' + e);
        setError(e);
        reject(e);
        return;
      }
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          /**
           * Create a new audio context and build a stream source,
           * stream destination and a gain node. Pass the stream into
           * the mediaStreamSource so we can use it in the Web Audio API.
           */
          setRawStream(stream);
          const context = new AudioContext();
          const mediaStreamSource = context.createMediaStreamSource(stream);
          const mediaStreamDestination = context.createMediaStreamDestination();
          const gainNode: GainNode = context.createGain();

          // /**
          //  * Connect the stream to the gainNode so that all audio
          //  * passes through the gain and can be controlled by it.
          //  * Then pass the stream from the gain to the mediaStreamDestination
          //  * which can pass it back to the RTC client.
          //  */
          mediaStreamSource.connect(gainNode);
          gainNode.connect(mediaStreamDestination);
          setGainNode(gainNode);

          /**
           * Use the stream that went through the gainNode. This
           * is the same stream but with altered input volume levels.
           */

          setStream(mediaStreamDestination.stream);
          resolve(mediaStreamDestination.stream);
          return mediaStreamDestination.stream;
        })
        .catch((e) => {
          reject();
          Sentry.captureException(e, {
            extra: {
              msg: 'error starting mic stream',
            },
          });
          setStream(null);
          console.error('error starting mic stream:' + e);
          setError(e);
          return null;
        });
    });
  }, []);

  useEffect(() => {
    streamRef.current = stream;
  }, [stream]);

  useEffect(() => {
    return () => {
      if (streamRef.current?.getTracks) {
        streamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    startStream();
  }, [startStream]);

  /** @type {(volume: number) => void} */
  const setVolume = useCallback(
    (volume: number) => {
      console.log('set volume to ' + volume);
      if (!gainNode) {
        console.error('Gain node not ready to have volume changed');
        return;
      }
      gainNode.gain.value = volume;
    },
    [gainNode]
  );

  const micValue = { rawStream, stream, error, setVolume, startStream };

  return (
    <MicrophoneStreamContext.Provider value={micValue}>
      {children}
    </MicrophoneStreamContext.Provider>
  );
};

export default MicrophoneStreamProvider;
