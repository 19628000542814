import styled from 'styled-components';
import { linkCss } from '../../../App.styled';

export const TemplateButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
  margin: 40px 0;
  flex-wrap: wrap;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const Disclaimer = styled.p`
  font-size: 14px;
  font-weight: 400;
  a {
    ${linkCss}
  }
`;
