import Axios from 'axios';
import { useEffect, useState } from 'react';

export const sendGetRequest = async (path: string) => {
  return await Axios.get(`${import.meta.env.VITE_END_POINT}${path}`);
};

const useGetDeepgramToken = () => {
  const [sttToken, setSttToken] = useState('');
  useEffect(() => {
    const getToken = async () => {
      const sttTokenResponse = await sendGetRequest('stt-token');
      setSttToken(sttTokenResponse?.data ?? '');
    };
    getToken();
  }, []);

  return sttToken;
};

export default useGetDeepgramToken;
