import styled from 'styled-components';
import { MOBILE_PLAYER_SIZE } from '../VHPlayer.styled';

export const UnityPlayerWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  /* @media (max-width: 800px) {
    height: ${MOBILE_PLAYER_SIZE};
  } */
  canvas {
    width: 100%;
  }
`;
