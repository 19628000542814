export enum AppStage {
  INTRO,
  VH_TEMPLATE_SELECTOR,
  VH_EDITOR,
  PERSONAL_DATA,
  VH_PLAYER,
}

export function getStep(stage: AppStage) {
  switch (stage) {
    case AppStage.INTRO:
      return 'Intro';
    case AppStage.VH_TEMPLATE_SELECTOR:
      return 'Choose scenario';
    case AppStage.VH_EDITOR:
      return 'Edit scenario';
    case AppStage.PERSONAL_DATA:
      return 'Contact details';
    case AppStage.VH_PLAYER:
      return 'Play scenario';
  }
}
