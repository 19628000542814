import styled from 'styled-components';

export const MobileMenuWrapper = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1;
  left: 0;
  bottom: 0;
  top: 70px;
  opacity: ${(props) => (props.$isOpen ? '1' : '0')};
  transition: opacity 0.5s ease-out;
  pointer-events: ${(props) => (props.$isOpen ? 'all' : 'none')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
