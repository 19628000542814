import React, { useCallback } from 'react';
import * as Styled from './ChatInput.styled';

const ChatInput = React.forwardRef<
  HTMLInputElement,
  {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onEnter: () => void;
    value: string;
    placeholder: string;
    disabled: boolean;
    maxLength: number;
  }
>((props, ref) => {
  const { onChange, onEnter } = props;
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
      // use check on whitespace instead of just the enter button to ensure that enter
      // wasn't being used to confirm an IME autocomplete suggestion for example
      if (onEnter && e.target.value.indexOf('\n') !== -1) {
        onEnter();
      }
    },
    [onChange, onEnter]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (onEnter && e.key === 'Enter') {
        onEnter();
      }
    },
    [onEnter]
  );

  return (
    <Styled.ChatInput
      value={props.value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      ref={ref}
      placeholder={props.placeholder}
      disabled={props.disabled}
      maxLength={props.maxLength}
    />
  );
});

ChatInput.displayName = 'ChatInput';

export default ChatInput;
