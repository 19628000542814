import { Button } from '@virtidev/toolbox';
import Steps from '../Steps/Steps';
import * as Styled from './StepsBanner.styled';
import * as StyledForms from '../VHForms/VHForms.styled';
import { useIsMobileFooterLayout } from '../../utility/hooks/LayoutHooks';

const StepsBanner = ({
  buttonText,
  onContinue,
  totalSteps,
  currentStep,
  onBack,
}: {
  buttonText: string;
  onContinue: () => void;
  totalSteps: number;
  currentStep: number;
  onBack?: () => void;
  backText?: string;
}) => {
  const isMobileLayout = useIsMobileFooterLayout();
  return (
    <Styled.HeaderWrapper>
      <StyledForms.Section>
        <Styled.StepBanner>
          {!isMobileLayout && (
            <Steps totalSteps={totalSteps} currentStep={currentStep} />
          )}
          {onBack && (
            <Styled.BackWrapper>
              <Button color="secondary" type="submit" onClick={onBack}>
                Previous
              </Button>
            </Styled.BackWrapper>
          )}
          <Button color="primary" type="submit" onClick={onContinue}>
            {buttonText}
          </Button>
        </Styled.StepBanner>
      </StyledForms.Section>
    </Styled.HeaderWrapper>
  );
};

export default StepsBanner;
