import styled from 'styled-components';
import { MOBILE_PLAYER_SIZE } from '../VHPlayer.styled';

export const UnityLoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: ${(props) => props.theme.color.contrast.midnight};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  font-size: 3rem;
  background-color: ${(props) => props.theme.color.midnight};
  /* @media (max-width: 800px) {
    height: ${MOBILE_PLAYER_SIZE};
  } */
  svg {
    width: 12rem;
    height: 12rem;
    margin: auto;
    @media (max-height: 800px) {
      width: 6rem;
      height: 6rem;
    }
  }
  // this is somehow not being applied by the progress bar library (it is in core) so I've added it here
  text {
    text-anchor: middle;
    dominant-baseline: middle;
  }
`;

export const LoadingAssetsText = styled.div`
  font-size: 2rem;
`;
