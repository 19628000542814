import { ReactNode } from 'react';
import { Chat } from '../../../../stores/ChatsStore/ChatsStore.type';
import ChatBubble from './ChatBubble/ChatBubble';
import * as Styled from './ChatItem.styled';

const ChatItem = ({
  chatLog,
  children,
}: {
  chatLog: Chat;
  children?: ReactNode;
}) => {
  return (
    <Styled.Item chatType={chatLog.chatType}>
      {['user', 'bot', 'virti'].includes(chatLog.chatType) && (
        <Styled.ChatBubbleWrapper chatType={chatLog.chatType}>
          <ChatBubble chatType={chatLog.chatType}>
            {chatLog.text}
            {children}
          </ChatBubble>
        </Styled.ChatBubbleWrapper>
      )}
    </Styled.Item>
  );
};

export default ChatItem;
