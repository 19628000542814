import { create } from 'zustand';
import { File } from '../../types/File.type';

type ctaModelActiveType = 'booking' | 'finish' | null;

type VHStateStoreState = {
  isTalking: boolean;
  audioToPlay: File | null;
  timeStarted: number | null;
  ctaModalActiveType: ctaModelActiveType;
  sessionComplete: boolean;
  sessionCompleteReason: string;
  isSendingChat: boolean;
  chatSessionId: string | null;
  setAudioToPlay: (audio: File) => void;
  setTalking: (talking: boolean) => void;
  setTimeStarted: (timeStarted: number | null) => void;
  setCtaModalActiveType: (ctaModalActiveType: ctaModelActiveType) => void;
  setSessionComplete: (complete: boolean) => void;
  setSessionCompleteReason: (completeReason: string) => void;
  setSendingChat: (sending: boolean) => void;
  setChatSessionId: (chatSessionId: string) => void;
};

export const useVHStateStore = create<VHStateStoreState>((set) => ({
  isTalking: false,
  audioToPlay: null,
  timeStarted: 0,
  ctaModalActiveType: null,
  sessionComplete: false,
  sessionCompleteReason: '',
  isSendingChat: false,
  chatSessionId: null,
  setAudioToPlay: (audio: File) => set((_state) => ({ audioToPlay: audio })),
  setTalking: (talking: boolean) => set((_state) => ({ isTalking: talking })),
  setTimeStarted: (timeStarted: number | null) =>
    set((_state) => ({ timeStarted })),
  setCtaModalActiveType: (ctaModalActiveType: ctaModelActiveType) =>
    set((_state) => ({ ctaModalActiveType })),
  setSessionComplete: (complete: boolean) =>
    set((_state) => ({ sessionComplete: complete })),
  setSessionCompleteReason: (completeReason: string) =>
    set((_state) => ({ sessionCompleteReason: completeReason })),
  setSendingChat: (isSendingChat: boolean) =>
    set((_state) => ({ isSendingChat })),
  setChatSessionId: (chatSessionId: string) =>
    set((_state) => ({ chatSessionId })),
}));
