import styled from 'styled-components';

export const SectionWrapper = styled.div`
  padding: 0 clamp(20px, 5vw, 60px);
`;

export const Section = styled.section`
  max-width: 1280px;
  margin: 0 auto;
`;

export const HeaderContent = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme: { color } }) => color.midnight10};
  @media (max-width: 800px) {
    br {
      display: none;
      width: 1em;
    }
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const Card = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 800px) {
    br {
      display: none;
      width: 1em;
    }
  }
  img {
    display: inline-block;
    width: 100%;
  }
  box-shadow: 0 4px 4px 0 #e1e1e5;
  background-color: #fff;

  border: none;
  flex: 1;
  text-align: left;
  padding: 0;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.color.primaryDark};
`;

export const CardContent = styled.div`
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;
