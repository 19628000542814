import { useEffect, useState } from 'react';
import * as Styled from './MutedNotification.styled';
import { Icon } from '@virtidev/toolbox';
import useMicStreamVolume from '../../../contexts/MicrophoneStreamProvider/useMicStreamVolume';

const MutedNotification = ({
  active,
  showMutedNoticeIfTalked,
}: {
  active: boolean;
  showMutedNoticeIfTalked: boolean;
}) => {
  const [mutedNoticeActive, setMutedNoticeActive] = useState(false);
  const volume = useMicStreamVolume();
  const [hasShown, setHasShown] = useState(false);

  // show muted notice if volume high enough
  useEffect(() => {
    if (!showMutedNoticeIfTalked) return;
    if (volume > 0.2 && !hasShown) {
      setHasShown(true);
      setMutedNoticeActive(true);
    }
  }, [volume, showMutedNoticeIfTalked, hasShown]);

  // if muted notice becomes active, set a timer to hide it
  useEffect(() => {
    let timer: number;
    if (mutedNoticeActive) {
      timer = setTimeout(() => {
        setMutedNoticeActive(false);
      }, 4000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [mutedNoticeActive]);

  useEffect(() => {
    if (active) {
      setMutedNoticeActive(false);
    }
  }, [active]);

  return (
    <>
      {mutedNoticeActive && (
        <Styled.MutedMessage>
          <Icon size="1.5rem" icon="microphoneoff" type="solid" />
          <div>Click the microphone button to unmute</div>
        </Styled.MutedMessage>
      )}
    </>
  );
};

export default MutedNotification;
