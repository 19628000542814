import { useRef } from 'react';
import { track } from '../../utility/Tracking';
import * as Styled from './DemoForm.styled';
import useEffectOnceWhen from '@rooks/use-effect-once-when';

const DemoForm = () => {
  const containerRef = useRef(null);
  const hasOpenedPage = true;
  useEffectOnceWhen(() => {
    const { hbspt } = window as any;
    if (hbspt && containerRef.current) {
      // Hubspot doesn't provide a way to destroy created forms, so may be a memory leak here.
      hbspt.forms.create({
        region: 'na1',
        portalId: '7277851',
        formId: 'e6a37179-5372-4169-81c7-819de0ecc6ed',
        target: '#HubSpotFormContainer',
        onFormSubmit: function () {
          track('hubspot_form_submitted', {
            type: 'request a demo',
            flow: 'vh teaser',
          });
        },
      });
    }
  }, hasOpenedPage);

  return (
    <Styled.HubSpotFormWrapper>
      <Styled.VirtiLink href="https://virti.com">
        <img src="virti-logo-dark.svg" alt="Virti logo" />
      </Styled.VirtiLink>
      <Styled.Heading>
        Get a Free Demo of Virti’s Immersive Learning Platform
      </Styled.Heading>
      <div id="HubSpotFormContainer" ref={containerRef} />
    </Styled.HubSpotFormWrapper>
  );
};

export default DemoForm;
