import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import * as Styled from './RecordingPopovers.styled';
import { useLocalStorage } from '@uidotdev/usehooks';
import DismissablePopover from '../../../../../../DismissablePopover/DismissablePopover';
import { useVHStateStore } from '../../../../../../../stores/VHStateStore/VHStateStore';

const RecordingPopovers = ({
  attachToRef,
  setShowMicUnavailable,
  showMicUnavailable,
  networkError,
}: {
  attachToRef: React.RefObject<HTMLButtonElement>;
  setShowMicUnavailable: (show: boolean) => void;
  showMicUnavailable: boolean;
  networkError: string | boolean | null;
}) => {
  const [vhMicTooltipDismissedForever, setVhMicTooltipDismissedForever] =
    useLocalStorage('mic_tooltip_dismissed', false);
  const [hasShownMicHelpPopover, setHasShownMicHelpPopover] = useState(false);
  const [showMicHelpPopover, setShowMicHelpPopover] = useState(false);
  const setInactiveTooltip = useCallback(() => {
    setShowMicHelpPopover(false);
  }, []);

  const dismissForever = useCallback(() => {
    setShowMicHelpPopover(false);
    setVhMicTooltipDismissedForever(true);
  }, [setVhMicTooltipDismissedForever]);

  const virtualHumanTalking = useVHStateStore((state) => state.isTalking);
  const isSendingChat = useVHStateStore((state) => state.isSendingChat);

  useEffect(() => {
    if (
      !isSendingChat &&
      !virtualHumanTalking &&
      !hasShownMicHelpPopover &&
      !vhMicTooltipDismissedForever
    ) {
      setHasShownMicHelpPopover(true);
      setShowMicHelpPopover(true);
    }
  }, [
    setShowMicHelpPopover,
    hasShownMicHelpPopover,
    isSendingChat,
    virtualHumanTalking,
    vhMicTooltipDismissedForever,
  ]);

  const setCloseMicPermissionsPopover = useCallback(() => {
    setShowMicUnavailable(false);
  }, [setShowMicUnavailable]);

  const [showNetworkError, setShowNetworkError] = useState(false);
  useEffect(() => {
    if (networkError) {
      setShowNetworkError(true);
    }
  }, [networkError]);

  const setCloseNetworkErrorPopover = useCallback(() => {
    setShowNetworkError(false);
  }, [setShowNetworkError]);

  return (
    <>
      {showMicUnavailable && (
        <DismissablePopover
          attachToRef={attachToRef}
          position={'top'}
          active={showMicUnavailable}
          setInactive={setCloseMicPermissionsPopover}
          type="error"
        >
          <Styled.DismissableContent>
            <div>
              Please enable microphone permissions in your browser and make sure
              your microphone is connected properly to be able to speak.
            </div>
          </Styled.DismissableContent>
        </DismissablePopover>
      )}
      {showNetworkError && (
        <DismissablePopover
          attachToRef={attachToRef}
          position={'top'}
          active={showNetworkError}
          setInactive={setCloseNetworkErrorPopover}
          type="error"
        >
          <Styled.DismissableContent>
            <div>There was an error with the speech service connection.</div>
          </Styled.DismissableContent>
        </DismissablePopover>
      )}
      {showMicHelpPopover && (
        <DismissablePopover
          attachToRef={attachToRef}
          position={'top'}
          active={showMicHelpPopover}
          setInactive={setInactiveTooltip}
          type="info"
        >
          <Styled.DismissableContent>
            <div>Click here if you'd like to use your microphone to speak.</div>
            <Styled.DismissForever onClick={dismissForever}>
              Don't show again
            </Styled.DismissForever>
          </Styled.DismissableContent>
        </DismissablePopover>
      )}
    </>
  );
};

RecordingPopovers.propTypes = {
  setShowMicUnavailable: PropTypes.func.isRequired,
  showMicUnavailable: PropTypes.bool.isRequired,
};

export default RecordingPopovers;
