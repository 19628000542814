import { TextInput } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const RoleplayTextArea = styled(TextInput)``;

export const Sliders = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;
export const Slider = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TraitsWarning = styled.div`
  color: ${(props) => props.theme.color.error};
`;

export const HeaderInfo = styled.p`
  max-width: 500px;
`;

export const SliderLabelWrapper = styled.label`
  display: flex;
  justify-content: space-between;
`;
export const SliderLabel = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme: { color } }) => color.midnight10};
`;
export const RangeInput = styled.input<{ $percentageValue: number }>`
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  accent-color: ${({ theme: { color } }) => color.blue};
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    height: 0.5rem;
    border-radius: 60px;
    background: ${({ theme: { color } }) => color.blue30};
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 60px;
    background-color: #fff;
    margin-top: -6px;
    height: 20px;
    width: 20px;
    border-radius: 60px;
    border: 1px solid ${({ theme: { color } }) => color.blue};
  }
  &:active&::-webkit-slider-thumb {
    background-color: ${({ theme: { color } }) => color.blue30};
  }

  &::-moz-range-track {
    background-color: ${({ theme: { color } }) => color.blue30};
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  &::-moz-range-thumb {
    border-radius: 60px;
    border: 1px solid ${({ theme: { color } }) => color.blue};
    margin-top: -6px;

    background-color: #fff;
    height: 20px;
    width: 20px;
  }
  &:active&::-moz-range-thumb {
    background-color: ${({ theme: { color } }) => color.blue30};
  }

  &:focus&::-moz-range-thumb {
  }

  &::-moz-range-progress {
    height: 8px;
    border-radius: 60px;
    background-color: ${({ theme: { color } }) => color.blue};
  }
  &::-moz-range-progress {
    height: 8px;
    border-radius: 60px;
    background-color: ${({ theme: { color } }) => color.blue};
  }
  &::-webkit-slider-runnable-track {
    ${({ theme, $percentageValue }) => css`
      background-image: linear-gradient(
        90deg,
        ${theme.color.blue} ${$percentageValue}%,
        ${theme.color.blue30} ${$percentageValue}%
      );
    `}
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  img {
    display: flex;
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 600px) {
      position: static;
    } */
  }
`;

export const InputsCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FormFields = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-auto-flow: dense;
  gap: 1rem;
  margin: 30px 0;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }

  /* @media (min-width: 1101px) {
    grid-template-columns: 2fr 1fr 0 1.5fr 1.5fr;
  } */

  // Override toolbox styles
  label + input[type='text'],
  textarea,
  .react-select__control {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 5px;
    border: 1px solid #cbd0db;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;

    &:disabled {
      background-color: #f2f3f2;
      border-color: #d1d1d1;
      color: #666;
    }
  }

  label + input[type='text'],
  textarea {
    display: block;
    min-height: auto;
    padding: 8px 11px 9px;
    width: 100%;
  }

  textarea {
    min-height: 168px;
    color: ${({ theme: { color } }) => color.midnight10};
    font-weight: 400;
  }
`;
export const ScenarioHeading = styled.h2`
  font-size: 19px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.midnight};
`;
export const SectionHeading = styled.h2`
  font-size: 19px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.midnight};
  border-bottom: 1px solid ${({ theme: { color } }) => color.blue40};
  margin-right: auto;
  padding: 1rem 2rem;
`;
export const AvatarWrapper = styled.div`
  box-shadow: 0 4px 4px 0 #e1e1e5;
  background-color: #fff;
`;

export const NameWrapper = styled.div``;

export const EnvironmentWrapper = styled.div``;

export const LanguageWrapper = styled.div``;

export const ScenarioWrapper = styled.div`
  padding: 1.4rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PersonalityWrapper = styled.div`
  /* grid-column: 1 / 3;

  @media (min-width: 1101px) {
    grid-column: 4 / 6;
  } */
`;

export const InformationWrapper = styled.div`
  grid-column: 1 / 3;

  @media (min-width: 1101px) {
    /* grid-column: 4 / 6;
    grid-row: span 2; */
    display: grid;
    grid-template-rows: auto 1fr;
  }
`;

export const MoodWrapper = styled.div`
  /* grid-column: 1 / 3;

  @media (min-width: 1101px) {
    grid-column: 4 / 6;
  } */
`;

export const LockedInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  &&&& input {
    font-size: 16px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const PersonalityInputs = styled.div`
  grid-template-columns: 1fr;
  gap: 2rem;
  display: flex;
  flex-direction: column;
`;
