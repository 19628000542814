import { useContext, useEffect, useRef, useState } from 'react';
import { MicrophoneStreamContext } from './MicrophoneStreamProvider';
import { useInterval } from '../../utility/hooks/useInterval';

const useMicStreamVolume = (updateInterval = 20, _active = true) => {
  const [volume, setVolume] = useState(0);
  const analyserNode = useRef<AnalyserNode | null>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const mediaStreamAudioSourceNode = useRef<MediaStreamAudioSourceNode | null>(
    null
  );
  const { rawStream } = useContext(MicrophoneStreamContext);

  useEffect(() => {
    if (!rawStream) {
      return;
    }
    async function getMedia() {
      if (!rawStream) {
        return;
      }
      try {
        audioContext.current = new AudioContext();
        mediaStreamAudioSourceNode.current =
          audioContext.current.createMediaStreamSource(rawStream);
        analyserNode.current = audioContext.current.createAnalyser();
        mediaStreamAudioSourceNode.current.connect(analyserNode.current);
      } catch (err) {
        /* handle the error */
      }
    }
    getMedia();
    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
      if (mediaStreamAudioSourceNode.current) {
        mediaStreamAudioSourceNode.current.disconnect();
      }
      if (analyserNode.current) {
        analyserNode.current.disconnect();
      }
    };
  }, [rawStream]);

  useInterval(() => {
    // useAnimationFrame(() => {
    if (!analyserNode.current) {
      return;
    }
    const bufferLength = analyserNode.current.fftSize;
    const dataArray = new Float32Array(bufferLength);
    analyserNode.current.getFloatTimeDomainData(dataArray);
    let sumSquares = 0.0;
    for (const amplitude of dataArray) {
      sumSquares += amplitude * amplitude;
    }
    const volume = Math.sqrt(sumSquares / dataArray.length);
    const visuallyBoostedVolume = volume * 3;
    setVolume(visuallyBoostedVolume);
    // });
  }, updateInterval);

  return volume;
};

export default useMicStreamVolume;
