import styled from 'styled-components';

export const Chat = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 24px;
  position: relative;
  grid-column: 1 / 3;
  column-span: 2;
  pointer-events: all;
  align-items: center;
  pointer-events: none;
  justify-content: flex-end;
  @media (max-width: 480px) {
    padding: 0;
  }
  gap: 20px;
`;
export const ChatLog = styled.div`
  width: clamp(340px, 80%, 900px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* @media (max-width: 800px) {
    width: 100%;
    background-color: ${(props) => props.theme.color.midnight};
    color: ${(props) => props.theme.color.contrast.midnight};
    padding: 1.5rem;
    overflow: auto;
    max-height: 25svh;
    padding: 1rem;
    flex: 1;
    display: flex;
    justify-content: center;
  } */
`;
export const InputArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  @media (max-width: 480px) {
    padding: 0;
  }
`;
export const ResendWrapper = styled.div`
  color: #fff;
  text-shadow: 1px 1px black;
  text-align: center;
  /* @media (max-width: 800px) {
    flex: 1;
  } */
`;
