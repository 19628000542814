// decimalised means that the time should return with deciseconds e.g. 0:40.6
// note: always rounds down
export const GetFormattedTime = function (ms: number, decimalised = false) {
  if (isNaN(ms) || !ms) return '0:00';

  // rounded to e.g. 550 to 6
  const roundedDeciSecondRemainder = Math.round((ms % 1000) / 100);
  ms = ms - roundedDeciSecondRemainder;
  const deciSecondStr =
    decimalised === true ? '.' + roundedDeciSecondRemainder : '';

  const seconds = ms / 1000;
  if (seconds < 60) {
    if (decimalised) {
      return `0:${(Math.floor(seconds) + '').padStart(2, '0')}${deciSecondStr}`;
    }
    return `0:${(Math.round(seconds) + '').padStart(2, '0')}`;
  }
  const minutes = Math.floor(seconds / 60);
  const secondsRemainder = seconds - minutes * 60;
  if (minutes < 60)
    return (
      minutes +
      ':' +
      (Math.floor(secondsRemainder) + '').padStart(2, '0') +
      deciSecondStr
    );
  const hours = Math.floor(seconds / 60 / 60);
  const minutesRemainder = minutes - hours * 60;
  return (
    hours +
    ':' +
    (Math.floor(minutesRemainder) + '').padStart(2, '0') +
    ':' +
    (Math.floor(secondsRemainder) + '').padStart(2, '0') +
    deciSecondStr
  );
};
