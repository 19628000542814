import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 2rem;
  display: flex;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5rem 1.5rem;
  svg path {
    fill: #000;
  }
  background: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  font-size: 20px;
  font-weight: bold;
`;
export const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 40px;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
`;
export const ProgressWrapper = styled.div``;
