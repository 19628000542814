import { createContext, createRef, useContext, FC } from 'react';

const vhWebModalRef = createRef<HTMLElement>();
const VHWebModalContext = createContext(vhWebModalRef);

export const VHWebModalContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <VHWebModalContext.Provider value={vhWebModalRef}>
      {children}
    </VHWebModalContext.Provider>
  );
};

export const useVHWebModalContext = () => useContext(VHWebModalContext);

export default VHWebModalContextProvider;
