import { Button } from '@virtidev/toolbox';
import styled from 'styled-components';

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const SubmitButton = styled(Button)`
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  width: 40px;
`;

export const ExtraWrapper = styled.div`
  display: flex;
  gap: 0.3rem;
`;

export const ChatInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 0.2rem 1rem 0.2rem 1rem;
  display: flex;
  gap: 0.6rem;
  max-width: 768px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: #fff;
  :focus-within {
    border: 1px solid ${(props) => props.theme.color.primary};
  }
  input,
  textarea {
    box-shadow: none !important;
  }
  @media (max-width: 480px) {
    border-radius: 0;
  }
`;

export const RecordingTextWrapper = styled.div`
  position: absolute;
  bottom: -2rem;
  right: 0rem;
`;
