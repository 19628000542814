import { useState, useRef, useCallback, useEffect, useContext } from 'react';
import useGetDeepgramToken from './hooks/useGetDeepgramToken';
import { MicrophoneStreamContext } from '../contexts/MicrophoneStreamProvider/MicrophoneStreamProvider';
import { usePrevious } from './hooks/StateHooks';

// needs updating when nova2 language support is expanded
const nova2Langs = [
  'en',
  'en-US',
  'en-AU',
  'en-GB',
  'en-NZ',
  'en-IN',
  'fr',
  'fr-FR',
  'fr-CA',
  'de',
  'hi',
  'hi-Latn',
  'pt',
  'pt-BR',
  'es',
  'es-419',
  // the following added based on the language page
  'bg-BG',
  'ca-ES',
  'zh-CN',
  'zh-TW',
  'cs-CZ',
  'da-DK',
  'nl-NL',
  'et-EE',
  'fi-FI',
  'nl-BE',
  'de-DE',
  'de-CH',
  'el-GR',
  'hi-IN',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'lv-LV',
  'lt-LT',
  'ms-MY',
  'no-NO',
  'pl-PL',
  'pt-PT',
  'pt-BR',
  'ro-RO',
  'ru-RU',
  'sk-SK',
];

export default function useDeepgram({
  // language,
  paused,
}: {
  // language: { BCP47Code: string; deepgramCode: string };
  paused: boolean;
}) {
  const [currentUnfinishedAffirmation, setCurrentUnfinishedAffirmation] =
    useState('');
  const [currentAffirmation, setCurrentAffirmation] = useState('');
  const [finalAffirmation, setFinalAffirmation] = useState('');
  const socketRef = useRef<WebSocket | null>(null);
  const [error, setError] = useState<any>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const micStreamRef = useRef<MediaStream | null>(null);
  const [connected, setConnected] = useState(false);
  const [mediaRecorderOn, setMediaRecorderOn] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [hasAttemptedConnection, _setHasAttemptedConnection] = useState(false);
  const {
    stream,
    startStream,
    error: _micStreamError,
  } = useContext(MicrophoneStreamContext);

  const createAndStartMediaRecorder = useCallback((stream: MediaStream) => {
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.start(1000);
    setMediaRecorderOn(true);
    return mediaRecorder;
  }, []);

  const stopMediaRecorder = useCallback((mediaRecorder: MediaRecorder) => {
    if (typeof mediaRecorder?.stop === 'function') {
      setMediaRecorderOn(true);
      mediaRecorder.stop();
    }
  }, []);

  const sttToken = useGetDeepgramToken();
  const closeSocket = useCallback(() => {
    console.log('close socket?');
    if (mediaRecorderRef?.current) {
      stopMediaRecorder(mediaRecorderRef.current);
      mediaRecorderRef.current = null;
    }
    if (socketRef.current) {
      console.log('close socket');
      console.log('socketRef.current.readyState');
      console.log(socketRef.current.readyState);
      if (
        typeof socketRef.current?.close === 'function' &&
        socketRef.current.readyState === 1
      ) {
        socketRef.current.close();
      }
      socketRef.current = null;
    }
  }, [stopMediaRecorder]);

  // connected ref to access inside callback functions
  const connectedRef = useRef(false);
  useEffect(() => {
    connectedRef.current = connected;
  }, [connected]);
  const currentAffirmationRef = useRef('');
  useEffect(() => {
    currentAffirmationRef.current = currentAffirmation;
  }, [currentAffirmation]);
  const pausedRef = useRef(false);

  const setupMediaRecorder = useCallback((socket: WebSocket) => {
    if (!mediaRecorderRef.current) {
      return;
    }
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      async (event) => {
        if (event.data.size > 0 && socket.readyState === 1) {
          // we have to send this constantly or the server connection to deepgram fails
          if (!pausedRef.current) {
            socket.send(event.data);
          }
        }
      }
    );
  }, []);

  const activateMicrophone = useCallback(async () => {
    console.log('activate mic');
    // TODO: MAKE SURE IF MIC HAS BEEN ACTIVATED THEN STT TOKEN IS SET
    // (may need to put in a context?)
    if (!sttToken) {
      return;
    }

    // don't run if already active
    if (
      connectedRef.current &&
      (!!mediaRecorderRef?.current || !!socketRef?.current)
    ) {
      console.log("already active: don't run");
      return;
    }
    if (socketRef.current) {
      console.log(socketRef.current);
      return;
    }
    if (!stream) {
      console.log('c');
      await startStream();
      if (!stream) return;
    }

    // set up mediarecorder now so that it can record while still connecting
    let mediaRecorder = null;
    try {
      mediaRecorder = createAndStartMediaRecorder(stream);
    } catch (e) {
      console.log('Failed to start mediarecorder: ');
      console.error(e);
      setMediaRecorderOn(false);
      return;
    }
    mediaRecorderRef.current = mediaRecorder;
    setConnecting(true);
    micStreamRef.current = stream;

    let localeCode = 'en';
    // let localeCode = language?.deepgramCode ?? language?.BCP47Code ?? 'en';
    // if english, use the local region
    if (
      localeCode?.indexOf('en') === 0 &&
      navigator.language.indexOf('en') === 0
    ) {
      localeCode = navigator.language;
    }
    let modelString = '';
    if (nova2Langs.includes(localeCode)) {
      modelString = '&model=nova-2';
    }

    const deepgramEndpoint = `wss://api.deepgram.com/v1/listen?utterance_end_ms=1500&punctuate=true&interim_results=true&endpointing=true&vad_turnoff=500${modelString}&language=${localeCode}`;

    const socket = new WebSocket(deepgramEndpoint, ['token', sttToken ?? '']);
    socket.onopen = () => {
      if (!mediaRecorderRef.current) return;
      setConnected(true);
      setupMediaRecorder(socket);
    };

    socket.onmessage = (message) => {
      console.log({ event: 'onmessage', message });
      setConnecting(false);

      console.log(pausedRef.current);
      if (pausedRef.current) return;
      const received = JSON.parse(message.data);

      if (received?.error) {
        console.log('deepgram errored');
        closeSocket();
        setError(received?.error);
        // setDeepgramErrorCount((c) => c + 1);
        return;
      } else {
        setError(null);
      }
      // setDeepgramErrorCount(0);
      // console.log(received);
      const transcript = received?.channel?.alternatives?.[0]?.transcript;
      if (received?.speech_final || received?.type === 'UtteranceEnd') {
        const finalAffirmation = (
          currentAffirmationRef.current +
          (currentAffirmationRef.current.length > 0 ? ' ' : '') +
          (transcript ?? '')
        ).trim();
        console.log(received?.speech_final ? 'speech final' : 'utterance end');
        console.log(finalAffirmation);
        setCurrentUnfinishedAffirmation('');
        setCurrentAffirmation('');
        if (finalAffirmation !== '') {
          setFinalAffirmation(finalAffirmation);
        }
      } else if (received?.is_final) {
        console.log('is final');
        console.log(
          `${currentAffirmationRef.current ?? ''}${
            transcript ? (transcript.length > 0 ? ' ' : '') + transcript : ''
          }`
        );
        const newCurrentAffirmation = `${currentAffirmationRef.current ?? ''}${
          transcript ? (transcript.length > 0 ? ' ' : '') + transcript : ''
        }`.trim();
        setCurrentUnfinishedAffirmation('');
        setCurrentAffirmation(newCurrentAffirmation);
      } else if (transcript) {
        console.log('unfinished');
        console.log(transcript);
        setCurrentUnfinishedAffirmation(transcript.trim());
      }
    };

    socket.onclose = () => {
      console.log({ event: 'onclose' });
      setConnecting(false);
      setConnected(false);
      closeSocket();
    };

    socket.onerror = (error) => {
      console.log({ event: 'onerror' });
      setConnecting(false);
      console.log('socket err');
      console.log(error);
      closeSocket();
      setConnected(false);
      setError(error);
    };

    socketRef.current = socket;
  }, [
    closeSocket,
    stream,
    startStream,
    // language?.BCP47Code,
    // language?.deepgramCode,
    sttToken,
    setupMediaRecorder,
    createAndStartMediaRecorder,
  ]);

  // pause toggled so resume or stop
  const prevPaused = usePrevious(paused);
  useEffect(() => {
    if (paused === prevPaused) {
      return;
    }
    setCurrentAffirmation('');
    setCurrentUnfinishedAffirmation('');
    setFinalAffirmation('');
    console.log(paused ? 'should pause' : 'should resume');
    if (paused) {
      closeSocket();
    }
    if (!paused && !connected) {
      activateMicrophone();
    }
    pausedRef.current = paused;
  }, [
    paused,
    prevPaused,
    closeSocket,
    activateMicrophone,
    mediaRecorderRef,
    stream,
    sttToken,
    connected,
  ]);

  const lastTranscriptTime = useRef(0);
  useEffect(() => {
    lastTranscriptTime.current = new Date().getTime();
  }, [currentUnfinishedAffirmation, currentAffirmation]);
  // ensure close socket if activateMicrophone has been run externally
  useEffect(() => {
    return () => {
      closeSocket();
    };
  }, [closeSocket]);

  const resetTranscript = useCallback(() => {
    setCurrentAffirmation('');
    setFinalAffirmation('');
    setCurrentUnfinishedAffirmation('');
  }, []);

  let currentTranscript = '';
  if (currentAffirmation) {
    currentTranscript =
      currentAffirmation +
      (currentAffirmation.length > 0 ? ' ' : '') +
      currentUnfinishedAffirmation;
  } else if (currentUnfinishedAffirmation) {
    currentTranscript = currentUnfinishedAffirmation;
  }

  return {
    currentTranscript,
    currentUnfinishedAffirmation,
    currentAffirmation,
    finalAffirmation,
    error,
    connected,
    reconnect: activateMicrophone,
    hasAttemptedConnection,
    resetTranscript,
    connecting,
    mediaRecorderOn,
  };
}
