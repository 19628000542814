import styled from 'styled-components';

export const MutedMessage = styled.div`
  position: fixed;
  top: calc(100px + 1rem);
  left: 38%;
  transform: translate(-50%);
  font-size: 0.9rem;
  border-radius: ${(props) => props.theme.borderRadius.md};
  background-color: ${(props) => props.theme.color.midnight};
  color: ${(props) => props.theme.color.contrast.midnight};
  padding: 0.5rem 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  left: 50%;
`;
