import { useLocalStorage } from '@uidotdev/usehooks';
import { createContext, useState } from 'react';

export const MicBehaviorContext = createContext({
  micActive: false,
  setMicActive: (_newVal: boolean) => {},
  micBehavior: '',
  setMicBehavior: (_newVal: string) => {},
});

export const MicBehaviorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [micBehavior, setMicBehavior] = useLocalStorage('micBehavior', 'open');
  const [micActive, setMicActive] = useState(false);

  return (
    <MicBehaviorContext.Provider
      value={{
        micActive,
        setMicActive,
        micBehavior,
        setMicBehavior,
      }}
    >
      {children}
    </MicBehaviorContext.Provider>
  );
};

export default MicBehaviorProvider;
