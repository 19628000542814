import styled from 'styled-components';

export const DismissableContent = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export const DismissForever = styled.button`
  border: none;
  display: inline-block;
  background: none;
  text-align: center;
  font-size: 0.8rem;
  padding: 0;
  cursor: pointer;
  color: ${(props) => props.theme.color.primaryDark};
  :hover {
    text-decoration: underline;
  }
`;
