import PropTypes from 'prop-types';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { LoadingFlavourText } from './LoadingFlavourText/LoadingFlavourText';
import * as Styled from './UnityLoader.styled';
import { useTheme } from 'styled-components';

const UnityLoader = ({
  progress,
  loadingStage,
  loadingFlavourTexts,
}: {
  progress: number;
  loadingStage: 'initial' | 'assets' | false;
  loadingFlavourTexts: string[];
}) => {
  const themeContext = useTheme();
  return (
    <Styled.UnityLoaderWrapper>
      {loadingStage === 'initial' && (
        <>
          <div>
            <CircularProgressbar
              value={progress ? progress : 0}
              text={`${progress}${
                progress && (progress + '').includes('%') ? '' : '%'
              }`}
              strokeWidth={7}
              styles={buildStyles({
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'round',
                textSize: '1.5rem',
                // Colors
                pathColor: themeContext.color.primary,
                textColor: themeContext.color.contrast.midnight,
                trailColor: themeContext.color.lightGrey,
                backgroundColor: '#000',
              })}
            />
          </div>
          {loadingFlavourTexts && (
            <LoadingFlavourText
              progress={progress}
              loadingFlavourTexts={loadingFlavourTexts}
            />
          )}
        </>
      )}
      {loadingStage === 'assets' && (
        <Styled.LoadingAssetsText>Loading assets...</Styled.LoadingAssetsText>
      )}
    </Styled.UnityLoaderWrapper>
  );
};

UnityLoader.defaultProps = {
  loadingStage: 'initial',
};

UnityLoader.propTypes = {
  progress: PropTypes.number,
  loadingFlavourTexts: PropTypes.arrayOf(PropTypes.string),
  loadingStage: PropTypes.string.isRequired,
};

export default UnityLoader;
