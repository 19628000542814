import * as Styled from './StartModal.styled';
import { useSelectedTemplateStore } from '../../../stores/SelectedTemplateStore/SelectedTemplateStore';

const StartModal = ({
  loaded,
  started,
  onStart,
}: {
  loaded: boolean;
  started: boolean;
  onStart: () => void;
}) => {
  const selectedTemplate = useSelectedTemplateStore(
    (state) => state.selectedTemplate
  );
  return (
    <Styled.StyledStartModal show={loaded && !started}>
      <Styled.ContentWrapper>
        {/* <Styled.ScenarioImageWrapper>
          <img
            src={selectedTemplate?.avatarSrc}
            alt={selectedTemplate?.avatarAltTag}
          />
        </Styled.ScenarioImageWrapper> */}
        <Styled.ScenarioName>
          {selectedTemplate?.templateName}
        </Styled.ScenarioName>
        <Styled.ScenarioObjective>
          {selectedTemplate?.templateDescription}
        </Styled.ScenarioObjective>
        <Styled.StartButton color="turquoise" onClick={onStart}>
          Start session
        </Styled.StartButton>
      </Styled.ContentWrapper>
    </Styled.StyledStartModal>
  );
};

export default StartModal;
