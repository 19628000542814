import { Tooltip } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const Menu = styled.div`
  padding: 0;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;
export const CardButton = styled.button`
  background: none;
  padding: 0.25rem 0.5rem;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  width: 100%;
  border: none;
  text-align: left;
  cursor: pointer;
`;

export const MicBehaviorTooltip = styled(Tooltip)`
  padding: 0;
  width: 44px;
  height: 44px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  // activate the hover color for desktop only - (hover:hover) was also activating for my mobile
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${({ theme }) => theme.color.turquoise10};
    }
  }
  ${({ disabled, theme }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: #525252;
      color: ${theme.color.lightGrey};
    `}
  background: transparent;
  color: ${({ theme }) => theme.color.base};
  &&:disabled,
  &&[disabled] {
    && * {
      pointer-events: none;
    }
    & {
      pointer-events: none;
      filter: grayscale() brightness(85%);
      color: ${({ theme }) => theme.color.midnight10};
    }
  }
`;
