import mixpanel from 'mixpanel-browser';
import { UserVHData } from '../stores/UserVHDataStore/UserVHDataStore';
import { PlayerTemplate } from '../types/PlayerTemplate.type';
import { AppStage, getStep } from './AppStages';

const params = new URL(window.document.location + '').searchParams;

export const USE_ANALYTICS = !params.get('noanalytics');

const projectToken = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN;
const debug = !!import.meta.env.VITE_MIXPANEL_DEBUG;
const shouldNotTrack = !projectToken || !USE_ANALYTICS;

const defaultTrackingProps = {
  channel: 'web_vh_teaser',
};

if (!shouldNotTrack) {
  mixpanel.init(projectToken, {
    debug,
    api_host: 'https://api-eu.mixpanel.com',
  });
}

export function track(eventName: string, props?: object, options?: object) {
  if (shouldNotTrack) return;
  mixpanel.track(eventName, { ...defaultTrackingProps, ...props }, options);
}

export function trackPageView(props?: object) {
  if (shouldNotTrack) return;
  mixpanel.track_pageview({ ...defaultTrackingProps, ...props });
}

export function getEditedFields(
  vhData: Partial<UserVHData>,
  selectedTemplate: PlayerTemplate
) {
  const editedFields = [];
  if (
    vhData.characterInformation !== selectedTemplate?.characterHints.join('\n')
  ) {
    editedFields.push('information');
  }
  if (
    vhData.personalityTraits &&
    vhData.personalityTraits.join(',') !==
      selectedTemplate?.personalityTraits
        .map((trait) => trait.toLowerCase())
        .join(',')
  ) {
    editedFields.push('personality');
  }
  if (vhData.confidence !== selectedTemplate.confidence)
    editedFields.push('mood: confidence');
  if (vhData.aggressiveness !== selectedTemplate.aggressiveness)
    editedFields.push('mood: aggressiveness');
  if (vhData.openness !== selectedTemplate.openness)
    editedFields.push('mood: openness');
  if (vhData.chattiness !== selectedTemplate.chattiness)
    editedFields.push('mood: chattiness');
  return editedFields;
}

export function trackPlayerCompletion(
  vhData: Partial<UserVHData>,
  selectedTemplate: PlayerTemplate | null,
  chatCount: number,
  completeReason: string,
  aborted = false
) {
  track(
    aborted ? 'step_aborted' : 'step_completed',
    {
      step: getStep(AppStage.VH_PLAYER),
      scenario: selectedTemplate?.templateName,
      edited_fields: selectedTemplate
        ? getEditedFields(vhData, selectedTemplate)
        : [],
      chat_count: chatCount,
      reason: completeReason,
    },
    aborted ? { transport: 'sendBeacon' } : undefined
  );
}

export function trackCtaClick(
  cta: 'free_trial' | 'book_demo',
  location: 'header' | 'end_modal' | 'end_chat'
) {
  track('cta_click', {
    cta,
    location,
  });
}
