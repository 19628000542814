import { ReactNode } from 'react';
import * as Styled from './ChatBubble.styled';

const ChatBubble = (props: {
  chatType: 'user' | 'bot' | 'virti';
  children: ReactNode;
}) => {
  return (
    <Styled.Bubble chatType={props.chatType}>{props.children}</Styled.Bubble>
  );
};

export default ChatBubble;
