import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useVHWebModalContext } from '../VHWebModalBase/VHWebModalBaseContext';
import { useInterval } from '../../utility/hooks/useInterval';
import * as Styled from './DismissablePopover.styled';
import { Icon } from '@virtidev/toolbox';

const PADDING = 10;

/** TODO: accommodate when container is fullscreen, properly implement positions */

const DismissablePopover = ({
  children,
  attachToRef,
  setInactive,
  position,
  active,
  className,
  type,
}: {
  children: React.ReactNode;
  attachToRef: React.RefObject<HTMLElement>;
  setInactive: (inactive: boolean) => void;
  position: string;
  active: boolean;
  className?: string;
  type: string;
}) => {
  const VHWebModalContext = useVHWebModalContext();

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [container] = React.useState(() => {
    const el = document.createElement('div');
    el.style.position = 'absolute';
    el.style.top = '0';
    el.style.left = '0';
    el.classList.add('portalwoop');
    return el;
  });

  const [posLeft, setPosLeft] = useState(-99999);
  const [posTop, setPosTop] = useState(-99999);
  const [arrowPosX, setArrowPosLeft] = useState(0);

  const getPositionCSS = useCallback(
    (position: string) => {
      if (!attachToRef?.current || !popoverRef?.current) {
        return null;
      }
      const attachedRect = attachToRef.current.getBoundingClientRect();
      const popoverRect = popoverRef.current.getBoundingClientRect();
      const calcLeftForMiddle =
        attachedRect.left +
        attachedRect.width / 2 +
        window.scrollX -
        popoverRect.width / 2;
      const calcLeftForRight =
        attachedRect.left - attachedRect.width + window.scrollX;
      const calcLeftForLeft =
        attachedRect.left + attachedRect.width + window.scrollX;
      const calcTopForTop =
        attachedRect.top + window.scrollY - popoverRect.height - PADDING;
      const calcTopForBottom =
        attachedRect.top + window.scrollY + attachedRect.height + PADDING;

      // not currently accounting for left/right/bottom-left/top-left
      switch (position) {
        case 'top-right':
          return {
            left: Math.max(
              0,
              Math.min(
                window.innerWidth - popoverRect.width - PADDING - 10,
                calcLeftForLeft
              )
            ),
            top: calcTopForTop,
          };
        case 'bottom-right':
          return {
            left: Math.max(
              0,
              Math.min(
                window.innerWidth - popoverRect.width - PADDING - 10,
                calcLeftForLeft
              )
            ),
            top: calcTopForBottom,
          };
        case 'bottom-left':
          return {
            left: Math.max(
              0,
              Math.min(
                window.innerWidth - popoverRect.width - PADDING - 10,
                calcLeftForRight
              )
            ),
            top: calcTopForBottom,
          };
        case 'bottom':
          return {
            left: Math.max(
              0,
              Math.min(
                window.innerWidth - popoverRect.width - PADDING - 10,
                calcLeftForMiddle
              )
            ),
            top: calcTopForBottom,
          };
        default:
          return {
            left: Math.max(
              0,
              Math.min(
                window.innerWidth - popoverRect.width - PADDING - 10,
                calcLeftForMiddle
              )
            ),
            top: calcTopForTop,
          };
      }
    },
    [attachToRef]
  );

  const getArrowPositionOffsetX = useCallback(() => {
    if (!attachToRef.current || !popoverRef.current) {
      return;
    }
    const attachedRect = attachToRef.current.getBoundingClientRect();
    const popoverRect = popoverRef.current.getBoundingClientRect();

    const centerXOfAttached = attachedRect.width / 2 + attachedRect.left;
    const diffToDesiredPosition = centerXOfAttached - popoverRect.left;
    const offsetPercentage = diffToDesiredPosition / popoverRect.width;
    return offsetPercentage;
  }, [attachToRef, popoverRef]);

  const reposition = useCallback(() => {
    if (attachToRef.current && popoverRef.current) {
      const pos = getPositionCSS(position);
      if (!pos) {
        return;
      }
      setPosLeft(pos.left);
      setPosTop(pos.top);

      const arrowPosLeft = getArrowPositionOffsetX();
      if (arrowPosLeft) {
        setArrowPosLeft(arrowPosLeft);
      }
    }
  }, [getPositionCSS, getArrowPositionOffsetX, position, attachToRef]);

  // ideally should use a ResizeObserver but the library is a bit much for just this one
  // instance when we can just be using an interval
  // (which shouldn't be run much anyway since it's designed to be dismissed)
  useInterval(() => {
    if (!active) return;
    // useEffectOnlyOnce(() => {
    reposition();
    // });
  }, 100);
  useEffect(() => {
    reposition();
  }, [reposition]);

  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  const handleClose = useCallback(() => {
    setInactive(true);
  }, [setInactive]);

  if (!VHWebModalContext.current) {
    return <></>;
  }

  return createPortal(
    <Styled.Popover
      ref={popoverRef}
      $position={position}
      $left={posLeft + 'px'}
      $top={posTop + 'px'}
      $arrowPosX={arrowPosX}
      aria-expanded={active ? 'true' : 'false'}
      hidden={!active}
      $active={active}
      className={className}
      $type={type}
    >
      <Styled.Content>{children}</Styled.Content>
      <div>
        <Styled.CloseBtn onClick={handleClose}>
          <Icon size=".7rem" icon="cross" />
        </Styled.CloseBtn>
      </div>
    </Styled.Popover>,
    VHWebModalContext.current
  );
};

DismissablePopover.defaultProps = {
  position: 'top-right',
};

DismissablePopover.propTypes = {
  children: PropTypes.node.isRequired,
  attachToRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  active: PropTypes.bool.isRequired,
  position: PropTypes.string, // top-right/top/bottom-right/bottom
  className: PropTypes.string,
  type: PropTypes.string,
};

export default DismissablePopover;
